import { useState } from "react";
import { Menu, Sidebar, MenuItem } from "react-pro-sidebar";
import { useProSidebar } from "react-pro-sidebar";

import { useSidebarContext } from "./sidebarContext";

import { Link } from "react-router-dom";
import { tokens } from "../../../theme";
import { useTheme, Box, Typography, IconButton } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ConstructionIcon from "@mui/icons-material/Construction";
import HouseSidingIcon from "@mui/icons-material/HouseSiding";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SwitchRightOutlinedIcon from "@mui/icons-material/SwitchRightOutlined";
import SwitchLeftOutlinedIcon from "@mui/icons-material/SwitchLeftOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import UploadFileIcon from '@mui/icons-material/UploadFile';

const menuItems = [
  {
    section: "General",
    roles: ["admin"],
    items: [
      { title: "Home", to: "/", icon: <HomeOutlinedIcon /> },
      { title: "Dashboard", to: "/dashboard", icon: <DashboardOutlinedIcon /> },
    ],
  },
  {
    section: "General",
    roles: ["user"],
    items: [{ title: "Home", to: "/", icon: <HomeOutlinedIcon /> }],
  },
  {
    section: "Expenses",
    roles: ["admin", "user"],
    typography: { variant: "h6",  m: "15px 20px 5px 20px" },
    items: [
      {
        title: "New Invoice",
        to: "/transactions/edit/new",
        icon: <ListAltIcon />,
      },
      {
        title: "Invoices",
        to: "/transactions/all",
        icon: <ReceiptOutlinedIcon />,
      },
    ],
  },
  {
    section: "Revenue",
    roles: ["admin"],
    typography: { variant: "h6", m: "15px 20px 5px 20px" },
    items: [
      {
        title: "New Payment",
        to: "/revenue/edit/new",
        icon: <CurrencyRupeeIcon />,
      },
      {
        title: "Payments Received",
        to: "/revenue/all",
        icon: <AccountBalanceIcon />,
      },
    ],
  },
  {
    section: "Manage",
    roles: ["admin", "user"],
    typography: { variant: "h6", m: "15px 20px 5px 20px" },
    items: [
      { title: "Projects", to: "/projects", icon: <HouseSidingIcon /> },
      { title: "Managers", to: "/managers", icon: <EngineeringIcon /> },
      { title: "Vendors", to: "/vendors", icon: <PersonOutlinedIcon /> },
      {
        title: "Materials",
        to: "/materials/edit/new",
        icon: <ConstructionIcon />,
      },
    ],
  },
  {
    section: "Advanced",
    roles: ["admin", "user"],
    typography: { variant: "h6", m: "15px 20px 5px 20px" },
    items: [
      { title: "File Upload", to: "/transactions/import", icon: <UploadFileIcon /> },
      ],
  }
];

const Item = ({ title, to, icon, selected, setSelected }) => {

  return (
    <MenuItem
      active={selected === title}
      onClick={() => setSelected(title)}
      icon={icon}
      routerLink={<Link to={to} />}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

const MyProSidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Dashboard");
  const { sidebarRTL, setSidebarRTL, sidebarImage, role } = useSidebarContext();
  const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();
  return (
    <Box
      sx={{
        position: "sticky",
        display: "flex",
        height: "100vh",
        top: 0,
        bottom: 0,
        zIndex: 10000,
        "& .sidebar": {
          border: "none",
        },
        "& .menu-icon": {
          backgroundColor: "transparent !important",
        },
        "& .menu-item": {
          // padding: "5px 35px 5px 20px !important",
          backgroundColor: "transparent !important",
        },
        "& .menu-anchor": {
          color: "inherit !important",
          backgroundColor: "transparent !important",
        },
        "& .menu-item:hover": {
          color: `${colors.blueAccent[500]} !important`,
        //  backgroundColor: "transparent !important",
        },
        "& .menu-item.active": {
          color: `${theme.palette.sidebar.default} !important`,
          backgroundColor: "transparent !important",
        },
      }}
    >
      <Sidebar
        breakPoint="md"
        rtl={sidebarRTL}
        backgroundColor={colors.primary[400]}
        image={sidebarImage}
      >
        <Menu iconshape="square">
          <MenuItem
            icon={
              collapsed ? (
                <MenuOutlinedIcon onClick={() => collapseSidebar()} />
              ) : sidebarRTL ? (
                <SwitchLeftOutlinedIcon
                  onClick={() => setSidebarRTL(!sidebarRTL)}
                />
              ) : (
                <SwitchRightOutlinedIcon
                  onClick={() => setSidebarRTL(!sidebarRTL)}
                />
              )
            }
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!collapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" >
                  {role && role.includes("admin") && <p>Admin </p>}
                  {role && role.includes("user") && <p>User</p>}
                </Typography>
                <IconButton
                  onClick={
                    broken ? () => toggleSidebar() : () => collapseSidebar()
                  }
                >
                  <CloseOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          <Box paddingLeft={collapsed ? undefined : "10%"}>
            {menuItems
              .filter(
                (section) =>
                  !section.roles ||
                  section.roles.some((allowedRole) =>
                    role?.includes(allowedRole)
                  )
              )
              .map((section) => (
                <Box key={section.section}>
                  {section.typography && (
                    <Typography {...section.typography}>
                      {section.section}
                    </Typography>
                  )}
                  {section.items.map((item) => (
                    <Item
                      key={item.title}
                      title={item.title}
                      to={item.to}
                      icon={item.icon}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  ))}
                </Box>
              ))}
          </Box>
        </Menu>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            textAlign: "center",
            fontSize: "0.8rem",
            display: window.innerHeight < 750 ? "none" : "block",
            p: 1,
          }}
        >
          Developed by{" "}
          <a
            href="https://www.parvatha.in"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "inherit" }}
          >
            parvatha.in
          </a>
        </Box>
      </Sidebar>
    </Box>
  );
};

export default MyProSidebar;
