// helpers.js
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { DataGrid, useGridApiContext } from "@mui/x-data-grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import { GridRowModes, GridActionsCellItem } from "@mui/x-data-grid";

const units = ["SFT", "LUMPSOME", "NO.S"];

function CustomEditComponent(props) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = (event) => {
    const eventValue = event.target.value; // The new value entered by the user
    console.log({ eventValue });
    const newValue =
      typeof eventValue === "string" ? value.split(",") : eventValue;
    apiRef.current.setEditCellValue({
      id,
      field,
      value: newValue.filter((x) => x !== ""),
    });
  };

  return (
    <Select
      labelId="demo-multiple-name-label"
      id="demo-multiple-name"
      multiple
      value={value}
      onChange={handleChange}
      sx={{ width: "100%" }}
    >
      {units.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
}
function CustomFilterInputSingleSelect(props) {
  const { item, applyValue, type, apiRef, focusElementRef, ...others } = props;

  return (
    <TextField
      id={`contains-input-${item.id}`}
      value={item.value}
      onChange={(event) => applyValue({ ...item, value: event.target.value })}
      type={type || "text"}
      variant="standard"
      InputLabelProps={{
        shrink: true,
      }}
      inputRef={focusElementRef}
      select
      SelectProps={{
        native: true,
      }}
    >
      {["", ...units].map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </TextField>
  );
}

const CustomUnitEditCell = (params) => <CustomEditComponent {...params} />;
export const getColumns = (
  rowModesModel,
  handleSaveClick,
  handleDeleteClick,
  handleEditClick,
  handleCancelClick
) => [
  { field: "name", headerName: "Material Name", width: 200, editable: true },
  {
    field: "unit",
    type: "singleSelect",
    minWidth: 120,
    flex: 1,
    editable: true,
    valueOptions: units,
    valueFormatter: ({ value }) => (value ? value.join("/") : ""),
    renderEditCell: CustomUnitEditCell,
    filterOperators: [
      {
        value: "contains",
        getApplyFilterFn: (filterItem) => {
          if (filterItem.value == null || filterItem.value === "") {
            return null;
          }
          return ({ value }) => {
            // if one of the cell values corresponds to the filter item
            return value.some((cellValue) => cellValue === filterItem.value);
          };
        },
        InputComponent: CustomFilterInputSingleSelect,
      },
    ],
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 200,
    renderCell: (params) => {
      const id = params.row.id;
      const isEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      return isEditMode ? (
        <>
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            onClick={handleSaveClick(id)}
          />
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            onClick={handleCancelClick(id)}
          />
        </>
      ) : (
        <>
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditClick(id)}
          />
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
          />
        </>
      );
    },
  },
];
