import React from 'react'
import PieChart from '../../components/PieChart'
import { Box } from '@mui/material';
import Header from '../../components/Header';
import { useParams } from "react-router-dom";

const Pie = () => {
    const { id } = useParams();

    return (
        <Box m="20px" height="75vh" p="2px">
            <Header title="Pie Chart" subtitle="simple Pie chart" />
            <PieChart id={id}/>
        </Box>
    )
}

export default Pie