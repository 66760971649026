import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { getTransactionsByProject } from "../data/getDataFromApi";
import * as XLSX from "xlsx";

const ExportInvoice = ({ token, id, name = "Invoices" }) => {
  // Sample data (assuming it's managed by state)

  const [data, setData] = useState([]);
  useEffect(() => {
     if(!id || !token) return;
    const fetchDataAsync = async () => {
      try {
        await getTransactionsByProject({
          token: token,
          id: id === "all" ? "" : id,
          setState: setData,
        });
      } catch (error) {
        console.error("Error fetching data from the API: ", error);
      }
    };

    if (token) {
      fetchDataAsync();
    }
  }, [token, id]);

  // Function to expand materialDetails and make each detail appear as a separate row
  const expandMaterialDetails = (data) => {
    const expandedData = [];
    data.forEach((item, index) => {
      item.materialDetails.forEach((detail, subIndex) => {
        const expandedItem = { ...item, ...detail };
        expandedItem.id = `${item._id}_${subIndex}`; // Using combination of _id and subIndex for uniqueness
        expandedData.push(expandedItem);
      });
    });
    return expandedData;
  };

  // Function to clean vendor, manager, and other fields to include only name
  const cleanFields = (data) => {
    const cleanedData = data.map((row) => {
      // Clean vendor field
      if (row.vendor) {
        row.vendor = row.vendor.name;
      }
      // Clean manager field
      if (row.manager) {
        row.manager = row.manager.name;
      }
      // Clean other fields similarly if needed
      // Add additional fields to clean here
      if (row.materialType) {
        row.materialType = row.materialType.name;
      }
      if (row.project) {
        row.project = row.project.name;
      }
      return row;
    });

    const reorderedData = cleanedData.map(
      ({
        _id,
        name,
        quantity,
        unit,
        price,
        itemTotal,
        vendor,
        manager,
        project,
        notes,
        transactionNumber,
        materialType,
        date,
      }) => ({
        _id,
        project,
        transactionNumber,
        materialType,
        name,
        quantity,
        unit,
        price,
        itemTotal,
        vendor,
        manager,
        notes,
        date,
      })
    );

    return reorderedData;
  };

  // Function to handle the click event for downloading the Excel file
  const handleDownload = () => {
    // Clean the data
    const cleanedData = cleanFields(expandMaterialDetails(data));

    // Convert data to Excel format
    const ws = XLSX.utils.json_to_sheet(cleanedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Convert workbook to binary string
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });

    // Create Blob from binary string
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create URL for Blob
    const url = window.URL.createObjectURL(blob);

    // Create link element
    const link = document.createElement("a");
    link.href = url;
    // Get current date
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getFullYear()}`;

    // Set the download attribute of the Blob with the desired filename including the current date
    let filename = `${name}_${formattedDate}.xlsx`;

    link.setAttribute("download", filename);

    // Simulate click on the link to trigger download
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  if (!id || !token) {
    return null;
  }
  return (
    <Box>
      <Button
        variant="contained"
        color="secondary"
        sx={{
          marginRight: 2,
        }}
        onClick={handleDownload}
      >
        Download
      </Button>
    </Box>
  );
};

export default ExportInvoice;
