import React from "react";
import { Box, Typography, Button, Container, Paper, Grid } from "@mui/material";
import Header from "../../components/Header";
import { LoginButton } from "../../components/buttons/login-button";

const PublicHome = () => {
  return (
    <Box
      sx={{
        background: "#FFDAB9", // Peach Puff color
        color: "#000000",
        minHeight: "100vh",
        paddingTop: 4,
        paddingBottom: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container maxWidth="lg">
        <Paper
          elevation={5}
          sx={{
            padding: 4,
            borderRadius: 8,
            background: "rgba(255, 255, 255, 0.8)",
          }}
        >
          <Box sx={{ flexGrow: 1, textAlign: "center" }}>
            <Grid container spacing={4}>

              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="center" // Center the Header and LoginButton
                  alignItems="center"
                >
                  <Header title="Accrual Accounting Software" />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box mt={4}>
                  <LoginButton />
                </Box>
                <Box mt={4}>
                  <Typography variant="h4" gutterBottom>
                    Revolutionize Your Construction Projects
                  </Typography>
                  <Typography variant="body1">
                    Discover the power of efficient project management with our Construction Management App.
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box mt={4}>
                  <Typography variant="h5" gutterBottom>
                    Get Started Today:
                  </Typography>
                  <Typography variant="body1">
                    Contact us now to experience the next level of construction project management.
                  </Typography>
                  <Box mt={2}>
                    <Button variant="contained" color="primary" sx={{ borderRadius: 20 }} onClick={() => { window.location.href = "https://www.parvatha.in/contact" }}>
                      Contact Us
                    </Button>
                  </Box>
                </Box>
              </Grid>

            </Grid>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default PublicHome;
