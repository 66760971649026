import { ColorModeContext, useMode } from "./theme";
import { useAuth0 } from "@auth0/auth0-react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route, Navigate } from "react-router-dom";
import { MyProSidebarProvider } from "./pages/global/sidebar/sidebarContext";

import Topbar from "./pages/global/Topbar";

import Dashboard from "./pages/dashboard";
import Team from "./pages/team";
import Form from "./pages/form";
import Calendar from "./pages/calendar";
import Bar from "./pages/bar";
import Line from "./pages/line";
import Pie from "./pages/pie";
import FAQ from "./pages/faq";
import Geography from "./pages/geography";
import EditTransaction from "./pages/transactions/edit";
import Transactions from "./pages/transactions";
import Revenue from "./pages/revenue";
import EditRevenue from "./pages/revenue/edit";
import EditManager from "./pages/managers/edit";
import MaterialType from "./pages/materials";
import EditMaterials from "./pages/materials/edit";
import Managers from "./pages/managers";
import Vendors from "./pages/vendors";
import EditVendor from "./pages/vendors/edit";
import Projects from "./pages/projects";
import EditProject from "./pages/projects/edit";
import Test from "./pages/test";
import { Home } from "./pages/home";
import { AuthenticationGuard } from "./components/authentication-guard";
import { CallbackPage } from "./pages/authentication/callback-page";
import { PageLoader } from "./pages/pageLoader";
import Profile from "./pages/profile";
import NotFound from "./pages/notfound";
import "./custom.css";
import PublicHome from "./pages/home/publicHome";
import VendorDashboard from "./pages/dashboard/vendorDashboard";
import useRole from "./hooks/useRole";
import ImportFromCSV from "./pages/transactions/import/import";

const App = () => {
  const [theme, colorMode] = useMode();
  const { isLoading, isAuthenticated } = useAuth0();
  const {role} = useRole();

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <div className="page-layout">
        <Routes>
          <Route path="/loader" element={<PageLoader />} />
          <Route path="/" element={<PublicHome />} />
          <Route path="/callback" element={<CallbackPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    );
  }

  let routes = null;

  if (role && role.includes("user")) {
    routes = [
      { path: "/loader", element: <PageLoader /> },
      { path: "/", element: <Navigate to="/transactions/edit/new" />},
      { path: "/transactions/:id", element: <Transactions /> },
      { path: "/transactions/edit/:id", element: <EditTransaction /> },
      { path: "/materials/edit/:id", element: <EditMaterials /> },
      { path: "/managers", element: <Managers /> },
      { path: "/managers/edit/:id", element: <EditManager /> },
      { path: "/vendors", element: <Vendors /> },
      { path: "/vendors/edit/:id", element: <EditVendor /> },
      { path: "/projects", element: <Projects /> },
      { path: "/projects/edit/:id", element: <EditProject /> },
      {
        path: "/secretpath",
        element: <AuthenticationGuard component={Test} />,
      },
      {
        path: "/profile",
        element: <AuthenticationGuard component={Profile} />,
      },
      { path: "/callback", element: <CallbackPage /> },
      { path: "*", element: <NotFound /> },
    ];
  } else if (role && role.includes("admin")) {
    routes = [
      { path: "/loader", element: <PageLoader /> },
      { path: "/", element: <Home /> },
      { path: "/dashboard", element: <Dashboard /> },
      { path: "/dashboard/vendor/:id", element: <VendorDashboard /> },
      { path: "/dashboard/:id", element: <Dashboard /> },
      { path: "/team", element: <Team /> },
      { path: "/managers", element: <Managers /> },
      { path: "/managers/edit/:id", element: <EditManager /> },
      { path: "/transactions/:id", element: <Transactions /> },
      { path: "/transactions/edit/:id", element: <EditTransaction /> },
      { path: "/transactions/import", element: <ImportFromCSV /> },
      { path: "/revenue/edit/:id", element: <EditRevenue /> },
      { path: "/revenue/:id", element: <Revenue /> },
      { path: "/form", element: <Form /> },
      { path: "/bar", element: <Bar /> },
      { path: "/pie/:id", element: <Pie /> },
      { path: "/line", element: <Line /> },
      { path: "/materials", element: <MaterialType/> },
      { path: "/materials/edit/:id", element: <EditMaterials /> },
      { path: "/faq", element: <FAQ /> },
      { path: "/calendar", element: <Calendar /> },
      { path: "/geography", element: <Geography /> },
      { path: "/vendors", element: <Vendors /> },
      { path: "/vendors/edit/:id", element: <EditVendor /> },
      { path: "/projects", element: <Projects /> },
      { path: "/projects/edit/:id", element: <EditProject /> },
      { path: "/test", element: <Test />},
      {
        path: "/secretpath",
        element: <AuthenticationGuard component={Test} />,
      },
      {
        path: "/profile",
        element: <AuthenticationGuard component={Profile} />,
      },
      { path: "/callback", element: <CallbackPage /> },
      { path: "*", element: <NotFound /> },
    ];
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MyProSidebarProvider role = {role}>
          <div style={{ height: "100%", width: "100%" }}>
            <main>
              <Topbar />
              <Routes>
                {routes?.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                ))}
              </Routes>
            </main>
          </div>
        </MyProSidebarProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
