import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const MaterialDataGrid = ({ data }) => {
  const columns = [
    { field: "materialType", headerName: "Material Type", width: 200 },
    { field: "date", headerName: "Date", width: 150 },
    { field: "vendor", headerName: "Vendor", width: 150 },
    {
      field: "project",
      headerName: "Project",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      type: "number",
      width: 150,
    },
    {
      field: "materialName",
      headerName: "Material Name",
      width: 200,
    },
    { field: "quantity", headerName: "Quantity", width: 150 },
    { field: "unit", headerName: "Unit", width: 150 },
    { field: "price", headerName: "Price", width: 150 },
  ];

  const getRowId = (row) => row.id; // Use the 'id' property as the unique identifier

  const rows = data.flatMap((material, materialIndex) =>
    material.materialDetails.map((detail, detailIndex) => ({
      id: `${materialIndex}-${detailIndex}`, // Combine material and detail indices for a unique ID
      materialType: material.materialType,
      date: new Date(material.date).toLocaleDateString("en-IN"),
      vendor: material.vendor,
      type: material.type,
      project: material.project,
      status: material.status,
      totalCost: material.totalCost,
      materialName: detail.materialName,
      quantity: detail.quantity,
      unit: detail.unit,
      price: detail.price,
    }))
  );

  return (
    <div style={{ height: "80vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        slots={{
          toolbar: GridToolbar,
        }}
        disableSelectionOnClick
        autoPageSize
        getRowId={getRowId} // Provide custom getRowId function
      />
    </div>
  );
};

export default MaterialDataGrid;
