import React from "react";
import "./index.css";

export const PageLoader = () => {
  const loadingGif = "https://media.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif";

  return (
    <div className="loader-container">
      <img className="loader-image" src={loadingGif} alt="Loading..." />
    </div>
  );
};
