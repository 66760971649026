import React from "react";
import { Grid, Typography } from "@mui/material";

const getFormattedAmount = (amount) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(amount);
};

const getFormattedDate = (inputDate) => {
  const date = new Date(inputDate);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Month is zero-based
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

const PrintableInvoice = React.forwardRef((props, ref) => {
  const { data } = props;

  if (!data) {
    return null;
  }
  const invoice = {
    companyName: "MI ASSOCIATES",
    companyAddress:
      "Behind Municipal Office, N R Extension, Chintamani, Karnataka 563125",
    companyContact: "Phone : +91 9480993030,  +91 91648967117, Email : miasoociates@gmail.com",
    companyLogoUrl: "/assets/logo.png",
    invoiceNumber: data.transactionNumber,
    invoiceDate: data.date,
    projectName: data?.project?.name,
    manager: data?.manager?.name,
    vendor: data?.vendor?.name,
    materialTypeName: data?.materialTypeName,
    materialDetails: data?.materialDetails,
    totalCost: data?.totalCost,
    notes: data?.notes,
  };

  const containerStyle = {
    background: "#FFFFFF", // White background
    color: "#000000", // Black text color
    padding: "16px",
    border: "1px solid #CCCCCC", // Lighter border color
  };

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "16px",
  };

  const thStyle = {
    borderBottom: "1px solid #CCCCCC", // Lighter border color
    padding: "8px",
    textAlign: "left",
  };

  const tdStyle = {
    borderBottom: "1px solid #CCCCCC", // Lighter border color
    padding: "8px",
    textAlign: "left",
  };

  return (
    <Grid container spacing={2} ref={ref} style={containerStyle}>
      <Grid container item xs={12} justifyContent="space-between" >
        <Grid item xs={5}>
          <Typography variant="h2" gutterBottom>
            <b> {invoice.companyName}</b>
          </Typography>
          <Typography>{invoice.companyAddress}</Typography>
          <Typography>{invoice.companyContact}</Typography>
        </Grid>
        <Grid item xs={3} mt={4}>
          <Typography >
            <b>Date : </b>
            {getFormattedDate(invoice.invoiceDate)}
          </Typography>
          <Typography>
            <b>Invoice Number :</b> {invoice.invoiceNumber}
          </Typography>
          <Typography>
            <b>Vendor :</b>
            {invoice.vendor}
          </Typography>
          <Typography>
          {" "}
          <b>Project : </b>
          {invoice.projectName}
        </Typography>
        <Typography>
          {" "}
          <b>Manager : </b>
          {invoice.manager}
        </Typography>
        </Grid>

      </Grid>
      
      <Grid item xs={12}>
      <hr style={{ margin: '20px 0', border: '1px solid #000' }} />


      </Grid>
      <Grid item xs={12}>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={thStyle}>Item</th>
              <th style={thStyle}>Quantity</th>
              <th style={thStyle}>Unit</th>
              <th style={thStyle}>Price</th>
              <th style={thStyle}>Total</th>
            </tr>
          </thead>
          <tbody>
            {invoice.materialDetails.map((material, index) => (
              <tr key={index}>
                <td style={tdStyle}>{material.name}</td>
                <td style={tdStyle}>{material.quantity}</td>
                <td style={tdStyle}>{material.unit}</td>
                <td style={tdStyle}>{getFormattedAmount (material.price)}</td>
                <td style={tdStyle}>{getFormattedAmount (material.itemTotal)}</td>
              </tr>
            ))}
            <tr key={"totalCost"}>
              <td colSpan="3"></td>
              <td style={tdStyle}><b>Total Cost</b></td>
              <td colSpan="1" style={tdStyle}>
              {getFormattedAmount (invoice.totalCost)}
              </td>
            </tr>
          </tbody>
        </table>
      </Grid>
      <Grid item xs={12}>
        <Typography>{invoice.notes}</Typography>
      </Grid>
    </Grid>
  );
});

export default PrintableInvoice;
