import { createContext, useState, useMemo } from "react";

import { createTheme } from "@mui/material/styles";

// color design tokens export
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        grey: {
          100: "#e0e0e0",
          200: "#c2c2c2",
          300: "#a3a3a3",
          400: "#858585",
          500: "#666666",
          600: "#525252",
          700: "#3d3d3d",
          800: "#292929",
          900: "#141414",
        },
        primary: {
          100: "#283A4E",
          200: "#24354A",
          300: "#202F46",
          400: "#1C2A41",
          500: "#18253D",
          600: "#141C2D",
          700: "#101823",
          800: "#0C1320",
          900: "#08101C",
          1000: "#202140",
        },
        greenAccent: {
          100: "#dbf5ee",
          200: "#b7ebde",
          300: "#94e2cd",
          400: "#70d8bd",
          500: "#4cceac",
          600: "#3da58a",
          700: "#2e7c67",
          800: "#1e5245",
          900: "#0f2922",
        },
        redAccent: {
          100: "#f8dcdb",
          200: "#f1b9b7",
          300: "#e99592",
          400: "#e2726e",
          500: "#db4f4a",
          600: "#af3f3b",
          700: "#832f2c",
          800: "#58201e",
          900: "#2c100f",
        },
        blueAccent: {
          100: "#e1e2fe",
          200: "#c3c6fd",
          300: "#a4a9fc",
          400: "#868dfb",
          500: "#6870fa",
          600: "#535ac8",
          700: "#3e4396",
          800: "#2a2d64",
          900: "#151632",
        },
      }
    : {
        grey: {
          100: "#292929",
          200: "#3d3d3d",
          300: "#525252",
          400: "#666666",
          500: "#858585",
          600: "#a3a3a3",
          700: "#c2c2c2",
          800: "#e0e0e0",
          900: "#f5f5f5",
        },
        primary: {
          100: "#ffffff",
          200: "#fcfcfc",
          300: "#f5f5f5",
          400: "#e0e0e0",
          500: "#c2c2c2",
          600: "#a3a3a3",
          700: "#858585",
          800: "#666666",
          900: "#525252",
        },
        greenAccent: {
          100: "#dbf5ee",
          200: "#b7ebde",
          300: "#94e2cd",
          400: "#70d8bd",
          500: "#4cceac",
          600: "#2e7c67",
          700: "#1e5245",
          800: "#0f2922",
          900: "#081512",
        },
        redAccent: {
          100: "#f8dcdb",
          200: "#f1b9b7",
          300: "#e99592",
          400: "#e2726e",
          500: "#db4f4a",
          600: "#af3f3b",
          700: "#832f2c",
          800: "#58201e",
          900: "#2c100f",
        },
        blueAccent: {
          100: "#e1e2fe",
          200: "#c3c6fd",
          300: "#a4a9fc",
          400: "#868dfb",
          500: "#6870fa",
          600: "#535ac8",
          700: "#3e4396",
          800: "#2a2d64",
          900: "#151632",
        },
      }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              main: colors.primary[100],//"#283A4E",
              light: colors.primary[200], // 200: "#24354A",
            },
            secondary: {
              main: colors.greenAccent[500], // 500: "#4cceac",
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.primary[600],  // 600: "#141C2D",
            },
            tableHeader: {
              default: colors.primary[200],
              toolbar: colors.greenAccent[500],
            },
            sidebar: {
              default: colors.blueAccent[500],
            },
          }
        : {
            // palette values for light mode
            primary: {
              main: colors.primary[900],
              light: colors.primary[100],
            },
            secondary: {
              main: colors.primary[800],
            },
            neutral: {
              dark: colors.blueAccent[700],
              main: colors.blueAccent[500],
              light: colors.blueAccent[100],
            },
            background: {
              default: colors.primary[100],
            },
            tableHeader: {
              default: colors.primary[500],
              toolbar: colors.primary[900],
            },
            sidebar: {
              default: colors.blueAccent[500],
            },
          }),
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 14,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
    overrides: {
      MuiOutlinedInput: {
        focused: {
          borderColor: colors.primary[900],
        },
        notchedOutline: {
          borderColor: colors.primary[900],
        },
      },
      MuiInputLabel: {
        root: {
          color: `rgba(${colors.primary[900]}, 6)`,
        },
      },
      MuiInputBase: {
        root: {
          color: colors.primary[900],
        },
      },
    },
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: colors.grey[100],
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          outlined: {
            "&:focus": {
              borderColor: colors.grey[100],
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.grey[100],
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: colors.grey[100],
              },
          },
        },
      },
    },
  };
};
// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("light");
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prevMode) => (prevMode === "dark" ? "light" : "dark")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return [theme, colorMode];
};
