import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Button, styled } from "@mui/material";

const StyledLoginButton = styled(Button)(({ theme }) => ({
  width: "150px", // Adjust as needed
  height: "50px", // Adjust as needed
  [theme.breakpoints.down("xs")]: {
    width: "120px", // Adjust for smaller screens
    height: "40px", // Adjust for smaller screens
  },
}));

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/",
      },
      authorizationParams: {
        prompt: "login",
      },
    });
  };

  return (
    <StyledLoginButton onClick={handleLogin} variant="contained" color="secondary">
      Log In
    </StyledLoginButton>
  );
};
