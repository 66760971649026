// EditToolbar.js
import React from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { GridToolbarContainer, GridRowModes } from "@mui/x-data-grid";

export default function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = Date.now();
    setRows((oldRows) => [...oldRows, { id, name: "", unit: [], isNew: true}]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    }));
  };

  return (
    <GridToolbarContainer>
      <div>
        <Button
          color="secondary"
          startIcon={<AddIcon />}
          onClick={handleClick}
        >
          Add Material
        </Button>
      </div>
    </GridToolbarContainer>
  );
}
