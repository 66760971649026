import { Box, Grid } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import StatBox from "../../StatBox";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
const DashboardTile = ({ backgroundColor, children }) => {
  return (
    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
      <Box
        width="100%"
        backgroundColor={backgroundColor}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {children}
      </Box>
    </Grid>
  );
};

const DashboardTiles = ({ project, expenses, revenue }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {/* First Dashboard Tile */}
      <DashboardTile backgroundColor={colors.primary[400]}>
        <StatBox
          title={revenue - expenses || 0}
          subtitle="Balance"
          progress="0.50"
          layout={"horizontal"}
          expenses={expenses}
          revenue={revenue}
          budget={project?.budget}
          icon={
            <CurrencyRupeeIcon
              sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
            />
          }
          barchart={true}
        />
      </DashboardTile>

      {/* Second Dashboard Tile */}
      <DashboardTile backgroundColor={colors.primary[400]}>
        <StatBox
          title={expenses}
          subtitle="Total Expenses"
          progress={expenses / project?.budget}
          increase={
            ((expenses / (project && project.budget)) * 100).toFixed(2) + "%"
          }
          icon={
            <CurrencyRupeeIcon
              sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
            />
          }
          progressCircle = {true}
        />
      </DashboardTile>

      {/* Third Dashboard Tile */}
      <DashboardTile backgroundColor={colors.primary[400]}>
        <StatBox
          title={revenue}
          subtitle="Payments Received"
          progress={revenue / project?.budget}
          increase={
            ((revenue / (project && project.budget)) * 100).toFixed(2) + "%"
          }
          icon={
            <CurrencyRupeeIcon
              sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
            />
          }
          progressCircle = {true}
        />
      </DashboardTile>

      {/* Fourth Dashboard Tile */}
      <DashboardTile backgroundColor={colors.primary[400]}>
      <StatBox
          title={project?.budget || 0}
          subtitle="Estimated Budget"
          progress="0.50"
          layout={"horizontal"}
          expenses={expenses}
          revenue={revenue}
          budget={project?.budget}
          icon={
            <CurrencyRupeeIcon
              sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
            />
          }
        />
      </DashboardTile>
    </Grid>
  );
};

export default DashboardTiles;
