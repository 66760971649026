import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { formatNumber } from "./utils/formatUtils";
import { darken } from "@mui/material/styles";

const BarChart = ({ layout, expenses = 50, revenue = 80, budget = 100, isDashboard=false}) => {
  const data = [
    {
      "Total Expenses": expenses || 50,
      "Payments Received": revenue || 80,
      "Estimated Budget": budget || 100,
    },
  ];
  return (
    <div style={{ height: "90px" }}>
      <ResponsiveBar
        data={data}
        keys={["Total Expenses",  "Payments Received", "Estimated Budget"]}
        indexBy="id"
        margin={isDashboard ? { top: 10, bottom: 10, right: 0, left: 0} : { top: 10, right: 10, bottom: 10, left: 10 }}

        
        colors={{ scheme: "accent" }}
        borderColor={{ theme: "background" }}
        enableGridX={false}
        groupMode="grouped"
        enableGridY={false}
        axisLeft={null}
        axisBottom={{
          tickSize: 1,
          tickPadding: 5,
          tickRotation: 0,
        }}
        layout={layout}
        //add custom name for hte baar label and value
        label={({ id, value }) => (
          <text>
          {id}: {value}
          </text>
        )}
        tooltip={({ id, value, color }) => (
          <strong style={{ color: darken(color, 0.3)  }}>
            {id}: {formatNumber(value)}
          </strong>
        )}
      />
    </div>
  );
};

export default BarChart;
