import React from "react";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import Typography from "@mui/material/Typography";

const NotificationSnackbar = ({ open, notification, onClose }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={1500}
      onClose={onClose}
      style={{
        left: "50%",
        transform: "translateX(-50%)",
        top: "16px", // Adjust the top value as needed
        bottom: "unset",
        alignItems: "center",
      }}
    >
      <SnackbarContent
        message={<Typography variant="body1">{notification}</Typography>}
        style={{
          backgroundColor:
            notification === "Error"
              ? "#f44336"
              : "#4caf50",
        }}
      />
    </Snackbar>
  );
};

export default NotificationSnackbar;
