import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  ListItemText,
  Checkbox,
  Select,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import NotificationSnackbar from "../../components/NotificationSnackbar";
import { useFormik } from "formik";
import projectValidationSchema from "../../data-validation/projectValidationSchema";
import {
  getProjects,
  getOnlyVendorNames,
  getOnlyManagerNames,
} from "../../data/getDataFromApi";
import { addProject, deleteProject } from "../../data/updateDataToApi";
import ConfirmDeleteDialog from "../../components/ConfirmDeleteDialog";
import SubmitButton from "../../components/SubmitButton";
import CancelButton from "../../components/CancelButton";
import FormHeader from "../../components/FormHeader";
import useAuthToken from "../../hooks/useAuthToken";

const ProjectForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [notification, setNotification] = useState(null);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [managers, setManagers] = useState([]);
  const [loading, setLoading] = useState(true);
  const {token} = useAuthToken();

  const formFields = [
    { name: "name", label: "Project Name", type: "text" },
    { name: "location", label: "Location", type: "text" },
    { name: "startDate", label: "Start Date", type: "date" },
    { name: "endDate", label: "End Date", type: "date" },
    { name: "clientName", label: "Client Name", type: "text" },
    {
      name: "managers",
      label: "Managers",
      type: "multi-select",
      options: managers,
    },
    {
      name: "vendors",
      label: "Vendors",
      type: "multi-select",
      options: vendors,
    },
    { name: "budget", label: "Budget", type: "number" },
    {
      name: "status",
      label: "Status",
      type: "select",
      options: [
        { _id: "Active", name: "Active" },
        { _id: "Inactive", name: "Inactive" },
      ],
      defaultValue: "Active",
    },
  ];

  const formik = useFormik({
    initialValues: {
      name: "",
      location: "",
      startDate: "",
      endDate: "",
      clientName: "",
      managers: [],
      vendors: [],
      budget: "",
      status: "Active",
    },
    validationSchema: projectValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {

        setSubmitting(true);

        if (id === "new") {
          await addProject({ data: values, token: token });
          setNotification("Project added successfully");
        } else {
          await addProject({ data: values, id: id, token: token });
          setNotification("Project updated successfully");
        }

        await new Promise((r) => setTimeout(r, 500));
        navigate("/projects");
        // Add logic to redirect to the project list or other page
      } catch (error) {
        
        if(error.message === "Conflict"){
          setNotification("Project name already exists");
        }
        else{
          setNotification( `Error ${id === "new" ? "adding" : "updating"} project`);
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { values, errors, touched } = formik;

  const renderOptions = (field) => {
    return field.options.map((option) => (
      <MenuItem key={option._id} value={option._id}>
        {field.type === "multi-select" ? (
          <Checkbox
            checked={(values[field.name] || []).indexOf(option._id) > -1}
            color="primary"
          />
        ) : null}
        <ListItemText primary={option.name} />
      </MenuItem>
    ));
  };

  const handleNotificationClose = () => {
    setNotification(null);
  };

  const handleDeleteClick = () => {
    setOpenConfirmDeleteDialog(true);
  };

  const handleDeleteCancel = () => {
    setOpenConfirmDeleteDialog(false);
  };

  const handleDeleteConfirm = () => {
    setOpenConfirmDeleteDialog(false);
    // Add your logic to delete the vendor
    deleteProject({ id: id, token: token })
    .then(() => {
      setNotification("Project deleted successfully");
      setTimeout(() => {
        navigate("/projects");
      }, 500);
    })
    .catch((error) => {
      console.error("Error deleting project:", error);
      setNotification("Error deleting project");
    });
  };

  useEffect(() => {
    if (!token) return;
    // Fetch clients, vendors, and managers for dropdowns
    const fetchDropdownData = async () => {
      try {
        await Promise.all([
          getOnlyVendorNames({ setState: setVendors, token: token }),
          getOnlyManagerNames({ setState: setManagers, token: token }),
        ]);
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDropdownData();

    const fetchProject = async () => {
      try {
        if (id !== "new") {
          const project = await getProjects({ id: id, token: token });
          setLoading(false);
          if (!project) {
            navigate("/projects");
            throw new Error("Project not found");
          }
          formik.setValues({
            ...project,
            managers: project.managers || [],
            vendors: project.vendors || [],
          });
        }
      } catch (error) {
        console.error("Error fetching project:", error);
      }
    };

    fetchProject();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, token]);

  const handleCancel = () => {
    if (navigate && navigate(-1)) {
      navigate(-1);
    } else {
      navigate("/projects");
    }

  };
  if (loading) return <div>Loading...</div>;

  return (
    <Box m="20px" mx="auto"  position="relative" maxWidth={1000}>
      <NotificationSnackbar
        open={!!notification}
        notification={notification}
        onClose={handleNotificationClose}
      />

      <FormHeader
        title={id === "new" ? "Add New Project" : "Edit Project Info"}
        buttonName={id === "new" ? "" : `Delete ${values.name}`}
        handleButtonClick={handleDeleteClick}
      />
      <form onSubmit={formik.handleSubmit} style={{ textAlign: "center" }}>
        <Grid container spacing={2}>
          {formFields.map((field, index) => (
            <Grid item xs={12} sm={6} key={field.name}>
              {field.type === "text" || field.type === "number" ? (
                <TextField
                  name={field.name}
                  label={field.label}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  type={field.type}
                  value={values[field.name]}
                  onChange={formik.handleChange}
                  error={touched[field.name] && !!errors[field.name]}
                  helperText={touched[field.name] && errors[field.name]}
                />
              ) : field.type === "date" ? (
                <TextField
                  name={field.name}
                  label={field.label}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  type={field.type}
                  value={values[field.name]}
                  onChange={formik.handleChange}
                  error={touched[field.name] && !!errors[field.name]}
                  helperText={touched[field.name] && errors[field.name]}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              ) : field.type === "select" ? (
                <FormControl fullWidth margin="normal" variant="outlined">
                  <InputLabel id={`${field.name}-label`}>
                    {field.label}
                  </InputLabel>
                  <Select
                    labelId={`${field.name}-label`}
                    id={field.name}
                    name={field.name}
                    value={values[field.name]}
                    onChange={formik.handleChange}
                    label={field.label}
                  >
                    {renderOptions(field)}
                  </Select>
                </FormControl>
              ) : field.type === "multi-select" ? (
                <FormControl fullWidth margin="normal" variant="outlined">
                  <InputLabel id={`${field.name}-label`}>
                    {field.label}
                  </InputLabel>
                  <Select
                    labelId={`${field.name}-label`}
                    id={field.name}
                    name={field.name}
                    value={values[field.name] || []}
                    onChange={formik.handleChange}
                    label={field.label}
                    multiple
                    renderValue={(selected) =>
                      selected
                        .map(
                          (value) =>
                            field.options.find((option) => option._id === value)
                              ?.name || ""
                        )
                        .join(", ")
                    }
                  >
                    {renderOptions(field)}
                  </Select>
                </FormControl>
              ) : (
                <div>Unsupported field type</div>
              )}
            </Grid>
          ))}
        </Grid>

        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} sm={12} m={5}>
            <SubmitButton
              isSubmitting={ formik.isSubmitting}
              isNew={id === "new"}
            />
            <CancelButton onClick={handleCancel} />
          </Grid>
        </Grid>

        <ConfirmDeleteDialog
          open={openConfirmDeleteDialog}
          onCancel={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
          id={id}
          navigate={navigate}
        />

      </form>
    </Box>
  );
};

export default ProjectForm;
