const ProgressBar = ({ progress, totalItems }) => (
    <div>
      {progress === totalItems && <div>Data Imported Successfully </div>}

      {progress < totalItems && (
        <>
          <div>
            Addding Inovice {progress} of {totalItems}
          </div>
          <div style={{ marginTop: "10px" }}>
            <progress value={progress} max={totalItems}></progress>
          </div>
        </>
      )}
    </div>
  );

  export default ProgressBar;