import { React, useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getVendors } from "../../data/getDataFromApi";
import { Link , useNavigate} from "react-router-dom";
import Header from "../../components/Header";
import useAuthToken from "../../hooks/useAuthToken";
import gridStyles from "../../components/gridStyles";

const Vendors = () => {
  const [vendorData, setvendorData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token } = useAuthToken();
  const navigate = useNavigate();

  const columns = [
    {
      field: "name",
      headerName: "Name",
      cellClassName: "name-column--cell",
      width: 200,
    },
    { field: "phone", headerName: "Phone Number", width: 100 },
    {
      field: "alternativePhone",
      headerName: "Alternative Phone Number",
      width: 100,
    },
    { field: "email", headerName: "Email", width: 200 },
    { field: "address", headerName: "Address", width: 250 },
    { field: "city", headerName: "City", width: 100 },
  ];

  const getRowId = (row) => row._id;

  useEffect(() => {
    if (!token) return;
    getVendors({ token: token, setState: setvendorData })
      .then(() => setLoading("loaded"))
      .catch((error) => {
        setLoading("ERROR, Please contact Admin");
      });
  }, [token]);

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Vendor Information" subtitle="Welcome to Vendor Data" />
        <Box>
          <Button
            component={Link}
            to="/vendors/edit/new"
            variant="contained"
            color="secondary"
          >
            Add New Vendor
          </Button>
        </Box>
      </Box>
      <Box m="8px 0 0 0" width="100%" height="80vh" sx={gridStyles}>
        <DataGrid
          rows={vendorData}
          columns={columns}
          slots={{
            toolbar: GridToolbar,
          }}
          loading={loading !== "loaded"}
          getRowId={getRowId}
          onCellDoubleClick={(params, event) => {
                navigate(`/vendors/edit/${params.row._id}`);
            }}
        />
      </Box>
    </Box>
  );
};

export default Vendors;
