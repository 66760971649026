// api.js
const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:6060/api";

const sendData = async (url, method, options) => {


  try {
    const { data, token } = options || {};
    const headers = token
      ? { Authorization: `Bearer ${token}`, "Content-Type": "application/json" }
      : { "Content-Type": "application/json" };
    const body = data ? JSON.stringify(data) : undefined;

    const response = await fetch(url, { method, headers, body });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return await response.json();
  } catch (error) {
    console.error(
      `Error ${method === "POST" ? "posting" : "deleting"} data to ${url}:`,
      error
    );
    throw error;
  }
};

const createRequestFunction =
  (endpoint, method) =>
  async ({ id, data, token }) =>
    sendData(
      `${apiUrl}/${endpoint}${id ? `/${id}` : ""}`,
      method,
      { data, token }
    );

export const addTransaction = createRequestFunction("transactions/post-transaction", "POST");
export const deleteTransaction = createRequestFunction(
  "transactions/delete-transaction",
  "DELETE"
);
export const addRevenue = createRequestFunction("revenue/post-revenue", "POST");
export const deleteRevenue = createRequestFunction("revenue/delete-revenue", "DELETE");
export const addProject = createRequestFunction("projects/post-project", "POST");
export const deleteProject = createRequestFunction("projects/delete-project", "DELETE");
export const addVendor = createRequestFunction("vendors/post-vendor", "POST");
export const deleteVendor = createRequestFunction("vendors/delete-vendor", "DELETE");
export const addMaterial = createRequestFunction("materials/post-material", "POST");
export const deleteMaterial = createRequestFunction("materials/delete-material", "DELETE");
export const addManager = createRequestFunction("managers/post-manager", "POST");
export const deleteManager = createRequestFunction("managers/delete-manager", "DELETE");
export const addMaterialName = createRequestFunction("materials/post-material", "POST");
export const deleteMaterialName = createRequestFunction("materials/delete-material", "DELETE");
export const addMaterialType = createRequestFunction("materialtypes/post-materialtype", "POST");
export const deleteMaterialType= createRequestFunction("materialtypes/delete-materialtype", "DELETE");
export const addTransactionLog = createRequestFunction("transactions/post-transaction-log", "POST");