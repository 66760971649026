import { React, useState, useEffect } from "react";
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { getManagers } from "../../data/getDataFromApi";
import {  useNavigate } from "react-router-dom";
import FormHeader from "../../components/FormHeader";
import useAuthToken from "../../hooks/useAuthToken";
import gridStyles from "../../components/gridStyles";

const Managers = () => {
  const [managerData, setmanagerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const {token} = useAuthToken();
  const navigate = useNavigate();
  const columns = [
    { field: "_id", headerName: "Id", width: 100, hide: true },
    {
      field: "name",
      headerName: "Name",
      cellClassName: "name-column--cell",
      width: 200,
    },
    { field: "phone", headerName: "Phone Number", width: 100 },
    {
      field: "alternativePhone",
      headerName: "Alternative Phone Number",
      width: 100,
    },
    { field: "email", headerName: "Email", width: 200 },
    { field: "address", headerName: "Address", width: 250 },
    { field: "city", headerName: "City", width: 100 },
    { field: "zipCode", headerName: "Zip Code", width: 100 },
  ];

  const getRowId = (row) => row._id;

  useEffect(() => {
    if (!token) return;
    getManagers({ token: token, setState: setmanagerData })
      .then(() => setLoading("loaded"))
      .catch((error) => {
        setLoading("ERROR, Please contact Admin");
      });
  }, [token]);

  return (
    <Box m="20px">
      <FormHeader
        title="Manager Information"
        subtitle="Welcome to Manager Data"
        buttonName="Add New Manager"
        to="/managers/edit/new"
        buttonColor="secondary"
      />
      <Box
        m="8px 0 0 0"
        width="100%"
        height="80vh"
        sx={gridStyles}
        >
        <DataGrid
          rows={managerData}
          columns={columns}
          slots={{
              toolbar: GridToolbar,
            }}
          loading={loading !== "loaded"}
          getRowId={getRowId}
          initialState={{
              columns: {
                columnVisibilityModel: {
                  _id: false,
                  alternativePhone: false,
                },
              },
            }}
            onCellDoubleClick={(params, event) => {
                navigate(`/managers/edit/${params.row._id}`);
            }}
        />
      </Box>
    </Box>
  );
};

export default Managers;
