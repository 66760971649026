import { DataGrid } from "@mui/x-data-grid";
import { useTheme, Box } from "@mui/material";
import getGridStyles from "../gridStyles";
import { useNavigate } from "react-router-dom";
import LoadingCircle  from "../LoadingCircle";
import { formatNumber } from "../utils/formatUtils";
const PendingVendorPayments = ({data, loading}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const gridStyles = getGridStyles(theme);

  const columns = [
    { field: "vendor", headerName: "Vendor", flex: 1 },
    { field: "totalCost", headerName: "Amount", flex: 1 ,
    renderCell: (params) => <>{formatNumber(params.value)}</>,},
  ];
  const handleSelect = (newSelection) => {
    navigate(`/dashboard/vendor/${newSelection[0]}`);
  };

  if(loading) return (
        <LoadingCircle />
    );
  return (
    <Box m="8px 0 0 0" width="100%" height="40vh" sx={gridStyles}>
      <DataGrid
        rows={data}
        columns={columns}
        onSelectionModelChange={handleSelect}
        getRowId={(row) => row._id}
      />
    </Box>
  );
};

export default PendingVendorPayments;
