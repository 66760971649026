import { useEffect, useState } from "react";
import { getUsersPermissions } from "../data/getDataFromApi";
import { useAuth0 } from "@auth0/auth0-react";

const useRole = () => {
  const [role, setRole] = useState(null);
  const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0();

  useEffect(() => {
    const fetchRole = async () => {
      try {
        // Ensure the user is authenticated and get the access token
        if (isAuthenticated) {
          const accessToken = await getAccessTokenSilently();
          const userRole = await getUsersPermissions({ token: accessToken, id: null });
          setRole(userRole);
        } else {
          // Handle the case where the user is not authenticated
          setRole(null);
        }
      } catch (error) {
        console.error("Error fetching user role:", error);

        // Check for the specific error related to a missing refresh token
        if (error.message.indexOf("Refresh Token") !== -1) {
          // Log out the user if the refresh token is missing
          logout();
        }

        setRole(null);
      }
    };

    // Call fetchRole when the component mounts
    fetchRole();
  }, [isAuthenticated, getAccessTokenSilently, logout]);

  return { role };
};

export default useRole;
