import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ExportInvoice from "../../components/ExportInvoice";
import { tokens } from "../../theme";
import {
  getProjects,
  getTransactionsByProject,
} from "../../data/getDataFromApi";
import useAuthToken from "../../hooks/useAuthToken";
import FormHeader from "../../components/FormHeader";
import { formatNumber } from "../../components/utils/formatUtils";
import gridStyles from "../../components/gridStyles";
import LoadingCircle from "../../components/LoadingCircle";

const Transactions = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { token } = useAuthToken();
  const { id } = useParams();
  const navigate = useNavigate();

  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [transactionItems, setTransactionItems] = useState([]);

  const columns = [
    { field: "transactionNumber", headerName: "Invoice Number", type: 'number', },
    { field: "_id", headerName: "Id", width: 70, defaultHidden: true },
    {
      field: "project",
      headerName: "Project",
      width: 150,
    },
    {
      field: "date",
      headerName: "Date",
      width: 100,
      renderCell: (params) => (
        <div>
          {params.value && new Date(params.value).toLocaleDateString("en-IN")}
        </div>
      ),
    },
    {
      field: "materialType",
      headerName: "Material Type",
      width: 150,
    },
    {
      field: "vendor",
      headerName: "Vendor",
      width: 150,
    },
    {
      field: "manager",
      headerName: "Manager",
      width: 150,
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      width: 120,
      type: 'number',
      renderCell: (params) => <>{formatNumber(params.value)}</>,
    },
    { field: "status", headerName: "Status", width: 120 },
    { field: "notes", headerName: "Notes", width: 200 },
  ];

  const getRowId = (row) => row._id;

  const cleanUpData = (data) => {
    return data.map((item) => {
      item.project = item.project.name.trim();
      item.vendor = item.vendor.name.trim();
      item.manager = item.manager.name.trim();
      item.materialType = item.materialType.name.trim();
      item.totalCost = item.totalCost || 0;

      return item;
    });
  };

  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        const projectsData = await getProjects({ token });
        const all = { _id: "all", name: "All" };
        setProjects([all, ...projectsData]);

        const transactionData = await getTransactionsByProject({
          token,
          id: id === "all" ? "" : id,
        });

        setTransactionItems(cleanUpData(transactionData));

        const selected =
          projectsData.find((project) => project._id === id) || all;

        setSelectedProject(selected);

        setLoading(false);
      } catch (error) {
        setTransactionItems([]);
        setLoading(false);
      }
    };

    if (token) {
      fetchDataAsync();
    }
  }, [token, id]);

  const handleSelectChange = (selectedValue) => {
    const selected =
      projects.find((project) => project._id === selectedValue) || projects[0];
    setSelectedProject(selected);
    navigate(`/transactions/${selected._id}`);
  };

  return (
    <Box m="20px" sx={gridStyles}>
      <FormHeader
        title="Invoices"
        subtitle="List of all the Invoices"
        buttonName="Add Invoice"
        to="/transactions/edit/new"
        buttonColor="secondary"
        buttonComponent={selectedProject?._id!=="all" ? <ExportInvoice token={token} id={selectedProject?._id} name={selectedProject?.name} /> : null}
        autoComplete={{
          options: projects.map((item) => ({
            value: item._id,
            label: item.name,
          })),
          value: {
            value: selectedProject?._id || projects[0]?._id || "",
            label: selectedProject?.name || projects[0]?.name || "",
          },
          label: "Project",
        }}
        onSelectChange={handleSelectChange}
      />

      <Box m="8px 0 0 0" width="100%" height="80vh">
        {loading ? (
          <LoadingCircle />
        ) : (
          <DataGrid
            rows={transactionItems}
            onCellDoubleClick={(params, event) => {
              if (params.field !== "edit") {
                navigate(`/transactions/edit/${params.row._id}`);
              }
            }}
            columns={columns}
            getRowId={getRowId}
            disableSelectionOnClick
            theme={(theme) => ({
              ...theme,
              palette: {
                ...theme.palette,
                primary: { main: colors.secondary },
                secondary: { main: colors.primary },
              },
            })}
            slots={{
              toolbar: GridToolbar,
            }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  _id: false,
                  status: false,
                },
              },
              sorting: {
                sortModel: [{ field: "transactionNumber", sort: "desc" }],
              },
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default Transactions;
