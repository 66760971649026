import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const useAuthToken = () => {
  const [token, setToken] = useState(null);
  const { user, getAccessTokenSilently, logout } = useAuth0();

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken);
      } catch (error) {
        console.error("Error fetching access token:", error);
        if (error.message.indexOf("Refresh Token") !== -1) {
          // Log out the user if the refresh token is missing
          logout();
        }
      }
    };

    fetchToken();
  }, [getAccessTokenSilently,logout]);

  return {user, token};
};

export default useAuthToken;
