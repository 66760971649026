import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Header from "./Header";
import { Link } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const FormHeader = ({
  title = "",
  subtitle = "",
  buttonName = "",
  to,
  handleButtonClick,
  buttonColor = "error",
  selectField,
  onSelectChange,
  autoComplete,
  buttonComponent,
}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      maxWidth="100%"
    >
      <Header title={title} subtitle={subtitle} />
      <Box display="flex">
        {buttonName && (
          <Button
            component={to ? Link : undefined}
            to={to}
            onClick={handleButtonClick}
            variant="contained"
            color={buttonColor}
            sx={{
              marginRight: 2,
            }}
          >
            {buttonName}
          </Button>
        )}
        {buttonComponent && (
          <>
            {buttonComponent}
          </>
        )}
        {selectField && (
          <Select
            value={selectField?.value}
            onChange={(e) => onSelectChange(e.target.value)}
            sx={{
              minWidth: 200,
              margin: 2,
              height: 40,
            }}
          >
            {selectField.options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
        {autoComplete && (
          <Autocomplete
            id="fomr-header-autocomplete"
            options={autoComplete.options}
            onChange={(e, selectedValue) => {
              // Check if the selectedValue is not null or undefined
              if (selectedValue) {
                onSelectChange(selectedValue?.value);
              }
            }}
            value={autoComplete?.value}
            isOptionEqualToValue={(option, value) =>
              option?.value === value?.value
            }
            renderInput={(params) => <TextField  {...params} label={autoComplete?.label}  size="small"   style={{ width: 200}}/>}
          />
        )}
      </Box>
    </Box>
  );
};

export default FormHeader;
