import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import { useParams } from "react-router-dom";
import RecentTransactions from "../../components/dashboard/RecentTransactions";
import FormHeader from "../../components/FormHeader";
import DashboardTiles from "../../components/dashboard/dashboard-tiles/DashboardTiles";
import MaterialsSummary from "../../components/dashboard/MaterialsSummary";
import useAuthToken from "../../hooks/useAuthToken";
import {
  getDashboardClientPayments,
  getDashboardExpensesTotal,
  getDashboardMaterialTypeTotal,
  getDashboardRecentTransactions,
  getProjects,
} from "../../data/getDataFromApi";
import PieChart from "../../components/PieChart";
import TreeMapChart from "../../components/TreeMapChart";
import ClientPayments from "../../components/dashboard/ClientPayments";
import ExportInvoice from "../../components/ExportInvoice";
import PieChartIcon from "@mui/icons-material/PieChart";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";

const Dashboard = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [chartType, setChartType] = useState("pie"); // ["pie", "tree"]
  const [projects, setProjects] = useState([]);
  const [selectedMaterialType, setSelectedMaterialType] = useState(null);
  const [expensesTotal, setExpensesTotal] = useState(null);
  const [clientPayments, setClientPayments] = useState(null);
  const [materialData, setMaterialData] = useState(null);
  const [recentTransactions, setRecentTransactions] = useState(null);
  const [loading, setLoading] = useState(true);
  const { token } = useAuthToken();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      if (!token) return;

      // Fetch projects and set selected project
      const projectsData = await getProjects({
        token,
        setState: setProjects,
        id: null,
      });
      const selected =
        projectsData.find((project) => project._id === id) || projectsData[0];
      setSelectedProject(selected);

      //if id is not present in the URL, redirect to the first project
      if (!id) {
        window.history.replaceState(null, null, `/dashboard/${selected._id}`);
      }
      
    };

    fetchData();
  }, [token, id]);

  useEffect(() => {
    const fetchData = async () => {
      if (!token || !selectedProject) return;

      // Fetch expenses total and client payments
      await getDashboardExpensesTotal({
        setState: setExpensesTotal,
        token,
        id: selectedProject._id,
      });
      await getDashboardClientPayments({
        setState: setClientPayments,
        token,
        id: selectedProject._id,
      });
    };

    fetchData();
  }, [token, selectedProject]);

  useEffect(() => {
    const fetchData = async () => {
      if (!token || !selectedProject) return;

      await getDashboardMaterialTypeTotal({
        id: selectedProject._id,
        materialType: selectedMaterialType?._id,
        setState: setMaterialData,
        token,
      });
      await getDashboardRecentTransactions({
        id: selectedProject._id,
        materialType: selectedMaterialType?._id,
        setState: setRecentTransactions,
        token,
      });
      setLoading(false);
    };

    fetchData();
  }, [token, selectedProject, selectedMaterialType]);

  const handleSelectChange = (selectedValue) => {
    const newUrl = `/dashboard/${selectedValue}`;
    window.history.replaceState(null, null, newUrl);

    // Set selected project and reset material type
    setSelectedProject(
      projects.find((project) => project._id === selectedValue)
    );
    setSelectedMaterialType(null);
  };

  const handleMaterialTypeSelect = (materialType) => {
    setSelectedMaterialType(materialType);
  };

  return (
    <Box sx={{ flexGrow: 1, margin: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {selectedProject ? (
            <FormHeader
              title={`${
                selectedProject?.name || projects[0]?.name || ""
              }'s Dashboard`}
              autoComplete={{
                options: projects.map((item) => ({
                  value: item._id,
                  label: item.name,
                })),
                value: {
                  value: selectedProject?._id || projects[0]?._id || "",
                  label: selectedProject?.name || projects[0]?.name || "",
                },
                label: "Project",
              }}
              buttonComponent={
                <ExportInvoice
                  token={token}
                  id={selectedProject?._id}
                  name={selectedProject?.name}
                />
              }
              buttonColor="secondary"
              onSelectChange={handleSelectChange}
            />
          ) : null}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <DashboardTiles
            project={selectedProject}
            expenses={expensesTotal ? expensesTotal[0]?.totalCost || 0 : 0}
            revenue={
              clientPayments ? clientPayments?.total[0]?.totalCost || 0 : 0
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h2" component="div" gutterBottom>
              Materials Breakdown
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
             
              <IconButton
                variant="contained"
                onClick={() => setChartType("pie")}
                sx={{ marginRight: 2 }}
              >
                <PieChartIcon />
              </IconButton>
              <IconButton
                variant="contained"
                onClick={() => setChartType("tree")}
                sx={{ marginRight: 2 }}
              >
                <AutoAwesomeMosaicIcon />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ height: 400, width: "100%" }}>
            {chartType === "pie" && (
              <PieChart data={materialData} loading={loading} />
            )}
            {chartType === "tree" && (
              <TreeMapChart data={materialData} loading={loading} />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography variant="h2" component="div" gutterBottom>
            Materials Summary
          </Typography>
          <MaterialsSummary
            data={materialData}
            loading={loading}
            budget={selectedProject?.budget}
            onMaterialTypeSelect={handleMaterialTypeSelect}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography variant="h2" component="div" gutterBottom>
            Recent Invoices
          </Typography>
          <RecentTransactions
            data={recentTransactions}
            loading={loading}
            dashboard={true}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography variant="h2" component="div" gutterBottom>
            Payments Received
          </Typography>
          <ClientPayments
            loading={loading}
            data={clientPayments?.result || []}
            dashboard={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
