import React, { useEffect, useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useParams } from "react-router-dom";

import useAuthToken from "../../hooks/useAuthToken";
import { getDashboardVendorPayments } from "../../data/getDataFromApi";
import MaterialDataGrid from "./MaterialDataGrid"
const VendorDashboard = () => {

  const [items, setItems] = useState([]);

  const {token} = useAuthToken();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch projects and update state
        await getDashboardVendorPayments({
          token: token,
          setState: setItems,
          id: id,
        });
      } catch (error) {
        // Handle error
      }
    };

    if (token) {
      fetchData();
    }
  }, [token, id]);


  return (
    <Box sx={{ flexGrow: 1, margin: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="h2" component="div" gutterBottom>
            Materials Summary
          </Typography>
          <MaterialDataGrid data={items} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default VendorDashboard;
