import React, { useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Input from "@mui/material/Input";
import Box from "@mui/material/Box";

import ImportDataGridComponent from "./ImportDataGridComponent";
import {
  validateFileType,
  validateFileSize,
  readDataFromXLSX,
  readDataFromCSV,
  checkMissingFields,
  cleanupData,
} from "./helper";
import { Button } from "@mui/material";
const ImportFromCSV = () => {
  const [fileData, setFileData] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setFileName(file.name);
    
    if (!file) return;
  
    if (!validateFileType(file)) {
      setError("Unsupported file format");
      setLoading(false);
      return;
    }
  
    if (!validateFileSize(file)) {
      setError("File size exceeds the limit (30 MB)");
      setLoading(false);
      return;
    }
  
    setLoading(true);
    setError(null);
  
    try {

      const arrayBuffer = await readFileAsync(file);
      let data;
      

      if (file.name.endsWith(".xlsx")) {
        data = readDataFromXLSX(arrayBuffer);
      } else if (file.name.endsWith(".csv")) {
        data = readDataFromCSV(arrayBuffer);
      }
  
      if (data.length === 0) {
        setError("The file is empty");
        setLoading(false);
        return;
      }
  
      let missingFields = checkMissingFields(data);
      if (missingFields.length > 0) {
        setError(`The file is missing the following fields: ${missingFields.join(", ")}`);
        setLoading(false);
        return;
      }
  
      let cleanedData = cleanupData(data);
      setFileData(cleanedData);
      setLoading(false);
    } catch (error) {
      console.error("Error reading file:", error);
      setError("Error reading file. Please try again.");
      setLoading(false);
    }
  };
  
  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = (e) => {
        resolve(e.target.result);
      };
  
      reader.onerror = (error) => {
        reject(error);
      };
  
      reader.readAsArrayBuffer(file);
    });
  };
  

  if (loading) {
    return (
      <Container>
        <Typography variant="h1">Loading...</Typography>
      </Container>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "top",
        alignItems: "top-left",
        m: "20px",
      }}
    >
      <Container>
        <Box
          sx={{
            m: "20px",
            display: "flex",
          }}
        >
          {/* File Selector */}
          {!fileData && (
            <>
              <Typography variant="h1" sx={{ mr: "30px" }}>
                Import
              </Typography>

              <Input
                type="file"
                onChange={handleFileChange}
                inputProps={{ accept: ".xlsx, .csv" }}
              />
            </>
          )}

          {fileData && (
            <>
              <Typography variant="h2">Imported File Name: {fileName}</Typography>
              <Button
                variant="contained"
                color="secondary"
                sx={{ marginLeft: "25px" }}
                onClick={() => setFileData(null)}
              >
                
                Clear
              </Button>
            </>
          )}
        </Box>

        {error && (
          <Box>
            <Typography variant="h2" color="error">
              {error}
            </Typography>
          </Box>
        )}
      </Container>

      <Box>{fileData && <ImportDataGridComponent data={fileData} />}</Box>
    </Box>
  );
};

export default ImportFromCSV;
