import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import {
  GridRowModes,
  DataGrid,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { getColumns } from "./helper";
import useAuthToken from "../../hooks/useAuthToken";
import { getMaterialTypes, getMaterials } from "../../data/getDataFromApi";
import {
  addMaterialName,
  deleteMaterialName,
} from "../../data/updateDataToApi";

import EditToolbar from "./EditToolbar";
import FormHeader from "../../components/FormHeader";
import NotificationSnackbar from "../../components/NotificationSnackbar";

export default function EditMaterials() {
  const { id } = useParams();
  const [rows, setRows] = useState([]);
  const [materialTypes, setMaterialTypes] = useState([]);
  const [selectedMaterialType, setSelectedMaterialType] = useState(null);
  const [rowModesModel, setRowModesModel] = useState({});
  const [notification, setNotification] = useState(null);
  const { token } = useAuthToken();
  const navigate = useNavigate();
  const [units, setUnits] = useState([]);

  useEffect(() => {
    if (!token) return;
    const fetchData = async () => {
      try {
        await getMaterials({
          token: token,
          setState: setRows,
          id: id ? id : "",
        });
        await getMaterialTypes({
          token: token,
          setState: setMaterialTypes,
        }).then((data) => {
          if (id && id !== "new") {
            const selected = data.find((item) => item._id === id);
            setSelectedMaterialType(selected);
            setUnits(selected?.units || []);
          } else {
            navigate(`/materials/edit/${data[0]?._id}`);
          }
        });
      } catch (error) {
        console.error("Error fetching material data:", error);
      }
    };

    fetchData();
  }, [token, id, navigate]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };
  const processRowUpdate = async (newRow) => {
    // Check if units is empty or name exists
    if (newRow.unit.length !== 0 && newRow.name.length !== 0) {
      const updatedRow = { ...newRow, isNew: newRow?.isNew || false };
      
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
  
      const existsInState = rows.some((item) => item.name === newRow.name && item.id !== newRow.id);
      
      if (existsInState) {
        setNotification("Duplicate Material Name");
        return;
      }
  
      const response = await addMaterialName({
        data: { ...updatedRow, materialtype: id },
        token: token,
        id: updatedRow._id || null,
      });

      if (response.success && newRow.isNew) {
        const newItem_id = response.data.insertedId;

        const updatedID = {
          ...newRow,
          id: newItem_id,
          _id: newItem_id,
          isNew: false,
        };
       
        setRows(rows.map((row) => (row.id === newRow.id ? updatedID : row)));
      }
  
      setNotification("Material Added/Updated");
  
      return updatedRow;
    } else {
      setNotification(newRow.unit.length === 0 ? "Units cannot be empty" : "Material Name cannot be empty");
      console.log("Invalid newRow:", newRow);
      return null;
    }
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => async () => {
    setRows(rows.filter((row) => row.id !== id));
    setNotification("Material Deleted");
    await deleteMaterialName({ token: token, id: id });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleSelectChange = (selectedValue) => {
    const selected =
      materialTypes.find(
        (materialTypes) => materialTypes._id === selectedValue
      ) || materialTypes[0];
    setSelectedMaterialType(selected);
    navigate(`/materials/edit/${selected._id}`);
  };

  const handleNotificationClose = () => {
    setNotification(null);
  };

  const columns = getColumns(
    rowModesModel,
    handleSaveClick,
    handleDeleteClick,
    handleEditClick,
    handleCancelClick,
    units
  );
  return (
    <Box sx={{ flexGrow: 1, margin: 4 }}>
      <NotificationSnackbar
        open={!!notification}
        notification={notification}
        onClose={handleNotificationClose}
      />
      <FormHeader
        title="Materials"
        subtitle="List of all the Materials"
        buttonName="Edit Category"
        to="/materials"
        buttonColor="secondary"
        autoComplete={{
          options: materialTypes.map((item) => ({
            value: item._id,
            label: item.name,
          })),
          value: {
            value: selectedMaterialType?._id || materialTypes[0]?._id || "",
            label: selectedMaterialType?.name || materialTypes[0]?.name || "",
          },
          label: "Material Type",
        }}
        
        onSelectChange={handleSelectChange}
      />
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid
          item
          xs={12}
          sx={{
            height: "70vh",
            width: "100%",
            "& .actions": {
              color: "text.secondary",
            },
            "& .textPrimary": {
              color: "text.primary",
            },
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            slots={{
              toolbar: EditToolbar,
            }}
            slotProps={{
              toolbar: { setRows, setRowModesModel },
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
