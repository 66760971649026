import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import useAuthToken from "../../../hooks/useAuthToken";
import {
  getProjects,
  getMaterialTypes,
  getManagers,
  getVendors,
} from "../../../data/getDataFromApi";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { submitBulkData } from "./submitBulkData";
import useActivityLog from "../../../hooks/useActivityLog";
import { checkErrors, checkAllErrors } from "./helper";
import ProgressBar from "../../../components/ProgressBar";

function EditToolbar(props) {
  return <GridToolbarContainer></GridToolbarContainer>;
}

const ImportDataGridComponent = (props) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [rows, setRows] = React.useState(props.data);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [errors, setErrors] = useState(true);

  const { user, token } = useAuthToken();
  const [materialTypes, setMaterialTypes] = useState([]);
  const [managers, setManagers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [progress, setProgress] = useState(0);
  const activityLog = useActivityLog("added", user?.name, "transaction via import");

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        await Promise.all([
          getProjects({ token: token, setState: setProjects }),
          getMaterialTypes({ token: token, setState: setMaterialTypes }),
          getManagers({ token: token, setState: setManagers }),
          getVendors({ token: token, setState: setVendors }),
        ]);
      } catch (error) {
        console.error("Error fetching projects:", error);
      } finally {
        setIsLoading(false);
        setSubmit(false);
      }
    };

    if (token) {
      fetchProjects();
    }
  }, [token]);

  useEffect(() => {
    if (submit) {
      const intervalId = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress + 1;
          if (newProgress === rows.length) {
            clearInterval(intervalId);
          }
          return newProgress;
        });
      }, 100);
    }
  }, [submit]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      //  event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(prevRows => {
      // Filtering out the row with the given id
      return prevRows.filter((row) => row.id !== id);
    });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const errors = checkErrors(
      newRow,
      isLoading,
      projects,
      vendors,
      materialTypes,
      managers
    );
    const updatedRow = {
      ...newRow,
      errors: Object.keys(errors).length > 0 ? Object.values(errors) : null,
    };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleExpand = (row) => {
    setExpandedRows((prevExpanded) => [...prevExpanded, row.index]);
    console.log(expandedRows);
  };

  const handleKeyDown = (event, params) => {
    if (
      event.key === "Tab" &&
      params.inputValue === "" &&
      params.highlightedIndex !== -1
    ) {
      // Prevent default Tab behavior
      event.preventDefault();

      // Set the selected value
      params.setOpen(false); // Close the dropdown
      params.setValue(params.options[params.highlightedIndex]);
    }
  };

  const columns = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 80,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    { field: "index", headerName: "Index", width: 30 },
    { field: "rowRange", headerName: "Row Ranges", width: 75 },
    {
      field: "CLIENT NAME",
      headerName: "Client Name",
      width: 300,
      editable: true,
      renderEditCell: (params) => (
        <Autocomplete
          style={{ width: "300px" }}
          options={projects.map((project) => ({
            label: project.name,
            value: project.name,
          }))}
          value={params.value || null} // value is the selected option
          onChange={(_, selectedOption) =>
            params.api.setEditCellValue({
              ...params,
              label: selectedOption?.label,
              value: selectedOption?.value,
            })
          }
          renderInput={(params) => <TextField {...params} />}
          isOptionEqualToValue={(option, value) => option.value === value}
          onKeyDown={handleKeyDown}
        />
      ),
    },
    {
      field: "VENDOR",
      headerName: "Vendor",
      width: 150,
      editable: true,
      renderEditCell: (params) => (
        <Autocomplete
          style={{ width: "350px" }}
          options={vendors.map((vendor) => ({
            label: vendor.name,
            value: vendor.name,
          }))}
          value={params.value || null} // value is the selected option
          onChange={(_, selectedOption) =>
            params.api.setEditCellValue({
              ...params,
              label: selectedOption?.label,
              value: selectedOption?.value,
            })
          }
          renderInput={(params) => <TextField {...params} />}
          isOptionEqualToValue={(option, value) => option.value === value}
          onKeyDown={handleKeyDown}
        />
      ),
    },
    {
      field: "CATEGORY",
      headerName: "Category",
      width: 150,
      editable: true,
      renderEditCell: (params) => (
        <Autocomplete
          style={{ width: "350px" }}
          options={materialTypes.map((materialType) => ({
            label: materialType.name,
            value: materialType.name,
          }))}
          value={params.value || null} // value is the selected option
          onChange={(_, selectedOption) =>
            params.api.setEditCellValue({
              ...params,
              label: selectedOption?.label,
              value: selectedOption?.value,
            })
          }
          renderInput={(params) => <TextField {...params} />}
          isOptionEqualToValue={(option, value) => option.value === value}
          onKeyDown={handleKeyDown}
        />
      ),
    },
    {
      field: "MIDDLEMAN",
      headerName: "Middleman",
      width: 150,
      editable: true,
      renderEditCell: (params) => (
        <Autocomplete
          style={{ width: "350px" }}
          options={managers.map((manager) => ({
            label: manager.name,
            value: manager.name,
          }))}
          value={params.value || null} // value is the selected option
          onChange={(_, selectedOption) =>
            params.api.setEditCellValue({
              ...params,
              label: selectedOption?.label,
              value: selectedOption?.value,
            })
          }
          renderInput={(params) => <TextField {...params} />}
          isOptionEqualToValue={(option, value) => option.value === value}
          onKeyDown={handleKeyDown}
        />
      ),
    },
    {
      field: "DATE",
      headerName: "Date",
      width: 100,
      type: Date,
      editable: true,
    },
    { field: "total", headerName: "Total", width: 75 },
    { field: "errors", headerName: "Errors", width: 120 },
    {
      field: "MATERIALS",
      headerName: "Materials",
      width: 350,
      renderCell: (params) => (
        <div style={{ maxHeight: "200px", overflowY: "auto" }}>
          {!expandedRows.includes(params.row.index) && (
            <div>
              <AddIcon
                style={{ cursor: "pointer", color: "green" }}
                onClick={() => {
                  console.log(params);
                  handleExpand(params.row);
                }}
              />
              {params.row.MATERIALS.length}{" "}
              {params.row.MATERIALS.length > 1 ? "Materials" : "Material"}
            </div>
          )}
          {expandedRows.includes(params.row.index) && (
            <div>
              <table style={{ textAlign: "center" }}>
                <thead>
                  <tr>
                    <th style={{ width: "200px" }}>Material</th>
                    <th style={{ width: "80px" }}>Quantity</th>
                    <th style={{ width: "80px" }}>Unit</th>
                    <th style={{ width: "80px" }}>Unit Price</th>
                    <th style={{ width: "80px" }}>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {params.row.MATERIALS.map((material, index) => (
                    <tr key={index}>
                      {material.map((item, subIndex) => (
                        <td key={subIndex}>{item}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      ),
    },
  ];

  const handleExpandAll = () => {
    setExpandedRows(rows.map((row) => row.index));
  };

  const handleCollapseAll = () => {
    setExpandedRows([]);
  };

  const handleCheckAllErrors = () => {
    if (isLoading) return;
    checkAllErrors(
      rows,
      setRows,
      setErrors,
      isLoading,
      projects,
      vendors,
      materialTypes,
      managers
    );
  };

  const ButtonsModule = () => (
    <div>
      <Button
        variant="contained"
        color="secondary"
        sx={{ marginLeft: "25px" }}
        disabled={submit}
        onClick={handleExpandAll}
      >
        Expand All
      </Button>
      <Button
        variant="contained"
        color="secondary"
        sx={{ marginLeft: "25px" }}
        disabled={submit}
        onClick={handleCollapseAll}
      >
        Collapse All
      </Button>
      <Button
        variant="contained"
        color="secondary"
        sx={{ marginLeft: "25px" }}
        disabled={submit}
        onClick={handleCheckAllErrors}
      >
        Check Errors
      </Button>
      <Button
        variant="contained"
        color="secondary"
        sx={{ marginLeft: "25px" }}
        disabled={errors || submit}
        onClick={() => {
          setSubmit(true);
          submitBulkData(
            rows,
            projects,
            materialTypes,
            vendors,
            managers,
            token,
            activityLog,
            user
          );
        }}
      >
        Submit Data
      </Button>
    </div>
  );

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <ButtonsModule />
      {submit && <ProgressBar progress={progress} totalItems={rows.length} />}
      <br />
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        getRowHeight={() => "auto"}
        disableClickEventBubbling={true}
        disableVirtualization={true}
      />
    </div>
  );
};

export default ImportDataGridComponent;
