import React, { useState, useEffect } from "react";
import { Box, Grid, TextField, ListItemIcon } from "@mui/material";
import {
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useParams, useNavigate } from "react-router-dom";
import { getMaterialTypes, getVendors } from "../../data/getDataFromApi";
import { useFormik } from "formik";
import vendorValidationSchema from "../../data-validation/vendorValidationSchema";
import { addVendor, deleteVendor } from "../../data/updateDataToApi";

import NotificationSnackbar from "../../components/NotificationSnackbar";
import ConfirmDeleteDialog from "../../components/ConfirmDeleteDialog";
import SubmitButton from "../../components/SubmitButton";
import CancelButton from "../../components/CancelButton";
import FormHeader from "../../components/FormHeader";
import useAuthToken from "../../hooks/useAuthToken";

const EditVendor = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState(null);
  const navigate = useNavigate();
  const [materialTypes, setMaterialTypes] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const { token } = useAuthToken(); // Use the custom hook to get the access token

  useEffect(() => {
    if (!token) return;
    const fetchMaterials = async () => {
      await getMaterialTypes({ setState: setMaterialTypes, token: token });
    };

    fetchMaterials();
  }, [token]);

  useEffect(() => {
    (async () => {
      if (!token) return;
      try {
        if (id !== "new") {
          const vendor = await getVendors({ token: token, id: id });
          if (!vendor) {
            navigate("/vendors");
            throw new Error("Vendor not found");
          }
          formik.setValues({
            ...vendor,
            supplyMaterials: vendor.supplyMaterials || [],
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, id]);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      alternativePhone: "",
      address: "",
      age: "",
      city: "",
      zipCode: "",
      supplyMaterials: [],
    },
    validationSchema: vendorValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        await addVendor({
          data: values,
          id: id !== "new" ? id : null,
          token: token,
        });
        setNotification(
          id === "new"
            ? "Vendor added successfully"
            : "Vendor updated successfully"
        );
        await new Promise((r) => setTimeout(r, 500));
        navigate("/vendors");
      } catch (error) {
        if (error.message === "Conflict") {
          setNotification("Vendor name already exists");
        } else {
          setNotification(
            `Error ${id === "new" ? "adding" : "updating"} Vendor`
          );
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { values, errors, touched } = formik;

  const handleMaterialTypesChange = (event) => {
    const selectedValues = event.target.value.filter(
      (value) => value !== "closeDialogItem"
    );
    formik.setFieldValue("supplyMaterials", selectedValues);
  };

  const formFields = [
    { name: "name", label: "Name", type: "text" },
    { name: "email", label: "Email", type: "text" },
    { name: "phone", label: "Phone", type: "text" },
    { name: "alternativePhone", label: "Alternative Phone", type: "text" },
    { name: "address", label: "Address", type: "text" },
    { name: "age", label: "Age", type: "number" },
    { name: "city", label: "City", type: "text" },
    { name: "zipCode", label: "Zip Code", type: "text" },
  ];

  const handleCancel = () => {
    if (navigate && navigate(-1)) {
      navigate(-1);
    } else {
      navigate("/vendors");
    }
  };

  const handleNotificationClose = () => {
    setNotification(null);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDeleteDialog(true);
  };

  const handleCancelDelete = () => {
    setOpenConfirmDeleteDialog(false);
  };

  const handleDeleteConfirm = () => {
    setOpenConfirmDeleteDialog(false);
    deleteVendor({ id: id, token: token })
      .then(() => {
        setNotification("Vendor deleted successfully");
        setTimeout(() => {
          navigate("/vendors");
        }, 500);
      })
      .catch((error) => {
        console.error("Error deleting vendor:", error);
        setNotification("Error deleting vendor");
      });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box m="20px" mx="auto" position="relative" maxWidth={1000}>
      <NotificationSnackbar
        open={!!notification}
        notification={notification}
        onClose={handleNotificationClose}
      />

      <FormHeader
        title={id === "new" ? "Add New Vendor" : "Edit Vendor Info"}
        buttonName={id === "new" ? "" : `Delete ${values.name}`}
        handleButtonClick={handleConfirmDelete}
      />
      <form onSubmit={formik.handleSubmit} style={{ textAlign: "center" }}>
        <Grid container spacing={2}>
          {formFields.map((field) => (
            <Grid item xs={12} sm={6} key={field.name}>
              <TextField
                name={field.name}
                label={field.label}
                fullWidth
                margin="normal"
                variant="outlined"
                type={field.type}
                value={values[field.name]}
                onChange={formik.handleChange}
                error={touched[field.name] && !!errors[field.name]}
                helperText={touched[field.name] && errors[field.name]}
              />
            </Grid>
          ))}
          <Grid item xs={12} sm={12} key="MaterialType">
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel id="supply-materials-label">
                Material Types
              </InputLabel>
              <Select
                labelId="supply-materials-label"
                id="supply-materials"
                name="supplyMaterials"
                multiple
                value={formik.values.supplyMaterials}
                onChange={handleMaterialTypesChange}
                open={isDropdownOpen}
                onOpen={() => setIsDropdownOpen(true)}
                onClose={() => setIsDropdownOpen(false)}
                label="Material Types"
                renderValue={(selected) => {
                  const selectedNames = selected.map(
                    (selectedId) =>
                      materialTypes.find(
                        (material) => material._id === selectedId
                      )?.name || ""
                  );
                  return selectedNames.join(", ");
                }}
                color="secondary"
              >
                {materialTypes.map((material) => (
                  <MenuItem key={material._id} value={material._id}>
                    <Checkbox
                      checked={formik.values.supplyMaterials.includes(
                        material._id
                      )}
                    />
                    <ListItemText primary={material.name} />
                  </MenuItem>
                ))}
                <MenuItem
                  value="closeDialogItem"
                  onClick={() => setIsDropdownOpen(false)}
                >
                  <ListItemText primary="Close" />
                  <ListItemIcon>
                    <CloseIcon fontSize="small" />
                  </ListItemIcon>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} sm={12} m={5}>
            <SubmitButton
              isSubmitting={formik.isSubmitting}
              isNew={id === "new"}
            />
            <CancelButton onClick={handleCancel} />
          </Grid>
        </Grid>

        {/* Delete Confirmation Dialog */}
        <ConfirmDeleteDialog
          open={openConfirmDeleteDialog}
          onCancel={handleCancelDelete}
          onConfirm={handleDeleteConfirm}
          id={id}
          navigate={navigate}
        />
      </form>
    </Box>
  );
};

export default EditVendor;
