import React from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

import RecentTransactions from "../../components/dashboard/RecentTransactions";
import ProjectsList from "../../components/dashboard/ProjectsList";
import ClientPayments from "../../components/dashboard/ClientPayments";
import PendingVendorPayments from "../../components/dashboard/PendingVendorPayments";
import { getDashboardClientPayments, getDashboardPendingVendorPaymentsSum, getDashboardProjectTotal, getDashboardRecentTransactions, getDashboardWeeklyTransactions } from "../../data/getDataFromApi";
import { useEffect, useState } from "react";
import useAuthToken from "../../hooks/useAuthToken";
import WeeklyStatsChart from "../../components/charts/weeklyStatsChart/WeeklyStatsChart";
import LoadingCircle from "../../components/LoadingCircle";



export const Home = () => {

  const [clientPayments, setClientPayments] = useState(null);
  const [projects, setProjects] = useState(null);
  const [vendorPaymentsSum, setVendorPaymentsSum] = useState(null);
  const [recentTransactions, setRecentTransactions] = useState(null);
  const [weeklyTransactions, setWeeklyTransactions] = useState(null);
  const [loading, setLoading] = useState(true);
  const { token } = useAuthToken();


  useEffect(() => {
    if (!token) return;
    const fetchData = async () => {
      await getDashboardProjectTotal({ setState: setProjects, token });
      await getDashboardRecentTransactions({ setState: setRecentTransactions, token });
      await getDashboardClientPayments({ setState: setClientPayments, token });
      await getDashboardPendingVendorPaymentsSum({ setState: setVendorPaymentsSum, token });
      await getDashboardWeeklyTransactions({ setState: setWeeklyTransactions, token });

      setLoading(false);
    };

    fetchData();
  } , [token]);

  if (loading) {return <LoadingCircle />;}

  return (
    <Box sx={{ flexGrow: 1, margin: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography variant="h2" component="div" gutterBottom>
            Weekly Insights
          </Typography>
          <WeeklyStatsChart data={weeklyTransactions} loading={loading}/>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography variant="h2" component="div" gutterBottom>
            Active Projects
          </Typography>
          <ProjectsList data={projects} loading={loading}/>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography variant="h2" component="div" gutterBottom>
            Recent Invoices
          </Typography>
          <RecentTransactions data={recentTransactions}/>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography variant="h2" component="div" gutterBottom>
            Client Payments 
          </Typography>
          <ClientPayments data ={clientPayments?.result} loading={loading}/>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography variant="h2" component="div" gutterBottom>
            Vendor Payments
          </Typography>
          <PendingVendorPayments data={vendorPaymentsSum} loading={loading} />
        </Grid>
      </Grid>
    </Box>
  );
};
