import * as yup from 'yup';

const materialDetailsSchema = yup.object({
  name: yup.string().required('Material Name is required'),
  quantity: yup.number().required('Quantity is required').min(1, 'Quantity must be at least 1'),
  unit: yup.string().required('Unit is required'),
  price: yup.number().min(0, 'Price must be at least 0'),
  itemTotal: yup.number().required('Item Total is required').min(0, 'Item Total must be at least 0'),
});

const transactionValidationSchema = yup.object({
  materialType: yup.object().required('Material Type is required'),
  date: yup.date().required('Date is required').nullable(),
  project: yup.object().required('Project name is required').typeError('Please select the project'),

  vendor: yup.object().required('Vendor is required').typeError('Please select the Vendor'),
  manager: yup.object().required('Manager is required').typeError('Please select the Manager'),
  materialDetails: yup.array().of(materialDetailsSchema).required('Material Details are required').min(1, 'Material Details must be at least 1'),
});

export default transactionValidationSchema;
