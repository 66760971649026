import * as Yup from 'yup';

const managerValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email format'),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, 'Phone must be a 10-digit number'),
  alternativePhone: Yup.string().matches(/^[0-9]{10}$/, 'Alternative Phone must be a 10-digit number'),
  address: Yup.string(),
  age: Yup.number()
    .typeError('Age must be a number')
    .min(18, 'Age must be greater than or equal to 18')
    .max(100, 'Age must be less than or equal to 100'),
  city: Yup.string(),
  zipCode: Yup.string()
    .matches(/^[0-9]{6}$/, 'Zip Code must be a 6-digit number'),
});

export default managerValidationSchema;
