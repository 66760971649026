import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { formatNumber } from "./utils/formatUtils";
import BarChart from "./BarChart";
import ProgressCircleFill from "./ProgressCircleFill";

const StatBox = ({ title, subtitle, progress, increase, icon, barchart,expenses, revenue, budget , progressCircle}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box width="100%" m="0 30px" p="12px 0">
      <Box display="flex" justifyContent="space-between">
        <Box>
          {icon}
          <Typography
            variant="h4"
            fontWeight="bold"
          >
            {typeof title === "number" ? formatNumber(title) : title}
          </Typography>
        </Box>

        {barchart && (
          <Box width="100px" height="55px">
          <BarChart
            layout={"vertical"}
            expenses={expenses ? expenses : 0}
            revenue={revenue ? revenue : 0}
            budget={budget ? budget : 0}
            isDashboard={true}
          />
          </Box>
        ) }
        {progressCircle &&  (
          <Box>
            <ProgressCircleFill progress={progress} />{" "}
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" mt="2px">
        <Typography variant="h5">
          {subtitle}
        </Typography>
        <Typography
          variant="h5"
          fontStyle="italic"
          sx={{ color: colors.greenAccent[600] }}
        >
          {increase}
        </Typography>
      </Box>
    </Box>
  );
};

export default StatBox;
