// PrintButton.js
import React from "react";
import Button from "@mui/material/Button";

const PrintButton = ({ onClick, disabled }) => (
  <Button
    type="button"
    variant="outlined"
    onClick={onClick}
    color="secondary"
    style={{ marginRight: "8px" }}
    disabled={disabled}
  >
    Print
  </Button>
);

export default PrintButton;
