import React from "react";
import { Box } from "@mui/material";

const ProgressCircleFill = ({ progress = 0.70, size = 40 }) => {
  const angle = progress * 360;

  return (
    <Box
      sx={{
        background: `conic-gradient(#4CCEAC ${angle}deg, transparent ${angle}deg 360deg), #266756`,
        borderRadius: "50%",
        width: `${size}px`,
        height: `${size}px`,
      }}
    />
  );
};

export default ProgressCircleFill;
