import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { getManagers } from "../../data/getDataFromApi";
import { useFormik } from "formik";
import managerValidationSchema from "../../data-validation/managerValidationSchema";
import { addManager, deleteManager } from "../../data/updateDataToApi";
import NotificationSnackbar from "../../components/NotificationSnackbar";
import FormHeader from "../../components/FormHeader";
import useAuthToken from "../../hooks/useAuthToken";
import SubmitButton from "../../components/SubmitButton";
import CancelButton from "../../components/CancelButton";
import ConfirmDeleteDialog from "../../components/ConfirmDeleteDialog";
const EditManager = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const {token} = useAuthToken(); // Use the custom hook to get the access token
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      alternativePhone: "",
      address: "",
      age: "",
      city: "",
      zipCode: "",
    },
    validationSchema: managerValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        await addManager({
          data: values,
          id: id === "new" ? null : id,
          token: token,
        });
        setNotification(
          id === "new"
            ? "Manager added successfully"
            : "Manager updated successfully"
        );
        await new Promise((r) => setTimeout(r, 500));
        navigate("/managers");
      } catch (error) {        
          if(error.message === "Conflict"){
            setNotification("Manager name already exists");
          }
          else{
            setNotification( `Error ${id === "new" ? "adding" : "updating"} Manager`);
          }
        }
    },
  });

  const { values, errors, touched } = formik;

  const formFields = [
    { name: "name", label: "Name", type: "text" },
    { name: "email", label: "Email", type: "text" },
    { name: "phone", label: "Phone", type: "text" },
    { name: "alternativePhone", label: "Alternative Phone", type: "text" },
    { name: "address", label: "Address", type: "text" },
    { name: "age", label: "Age", type: "number" },
    { name: "city", label: "City", type: "text" },
    { name: "zipCode", label: "Zip Code", type: "text" },
  ];

  useEffect(() => {
    if (!token) return;
    (async () => {
      try {
        if (id !== "new") {
          const manager = await getManagers({ token: token, id: id });

          if (!manager) {
            navigate("/managers");
            throw new Error("Manager not found");
          }
          formik.setValues(manager);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, id]);

  const handleCancel = () => {
    if (navigate && navigate(-1)) {
      navigate(-1);
    } else {
      navigate("/managers");
    }
  };

  const handleNotificationClose = () => {
    setNotification(null);
  };

  const handleDeleteClick = () => {
    setOpenDialog(true);
  };

  const handleDeleteConfirm = () => {
    setOpenDialog(false);
    deleteManager({ id: id, token: token })
    .then(() => {
      setNotification("Manager deleted successfully");
      setTimeout(() => {
        navigate("/managers");
      }, 500);
    })
    .catch((error) => {
      console.error("Error deleting Manager:", error);
      setNotification("Error deleting Manager");
    });
  };

  const handleDeleteCancel = () => {
    setOpenDialog(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box m="20px" mx="auto" position="relative" maxWidth={1000}>
      <NotificationSnackbar
        open={!!notification}
        notification={notification}
        onClose={handleNotificationClose}
      />

      <FormHeader
        title={id === "new" ? "Add New Manager" : "Edit Manager Info"}
        buttonName={id === "new" ? "" : `Delete ${values.name}`}
        handleButtonClick={handleDeleteClick}
      />
      <form onSubmit={formik.handleSubmit} style={{ textAlign: "center" }}>
        <Grid container spacing={2}>
          {formFields.map((field) => (
            <Grid item xs={12} sm={6} key={field.name}>
              <TextField
                name={field.name}
                label={field.label}
                fullWidth
                margin="normal"
                variant="outlined"
                type={field.type}
                value={values[field.name]}
                onChange={formik.handleChange}
                error={touched[field.name] && !!errors[field.name]}
                helperText={touched[field.name] && errors[field.name]}
              />
            </Grid>
          ))}
        </Grid>

        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} sm={12} m={5}>
            <SubmitButton
              isSubmitting={ formik.isSubmitting}
              isNew={id === "new"}
            />
            <CancelButton onClick={handleCancel} />
          </Grid>
        </Grid>

        {/* Delete Confirmation Dialog */}

        <ConfirmDeleteDialog
          open={openDialog}
          onCancel={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
          id={id}
          navigate={navigate}
        />
      </form>
    </Box>
  );
};

export default EditManager;
