import { DataGrid } from "@mui/x-data-grid";
import { useTheme, Box } from "@mui/material";
import getGridStyles from "../gridStyles";
import { useNavigate } from "react-router-dom";
import LoadingCircle from "../LoadingCircle";

import { formatNumber } from "../utils/formatUtils";

const ProjectsList = ({ data, loading }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const gridStyles = getGridStyles(theme);

  const columns = [
    { field: "projectName", headerName: "Project", flex: 1 },
    {
      field: "totalRevenue",
      headerName: "Total Revenue",
      flex: 1,
      renderCell: (params) => <>{formatNumber(params.value)}</>,
    },
    {
      field: "totalExpenses",
      headerName: "Total Expenses",
      flex: 1,
      renderCell: (params) => <>{formatNumber(params.value)}</>,
    },
    {
      field: "projectBudget",
      headerName: "Estimated Budget",
      flex: 1,
      renderCell: (params) => <>{formatNumber(params.value)}</>,
    },
  ];

  const handleRowClick = (params) => {
    navigate(`/dashboard/${params.row._id}`);
  };

  const getRowId = (row) => row._id;

  const getRowClassName = (params) => {
    return params.row.totalRevenue < params.row.totalExpenses ? "red-row" : "";
  };

  if (loading || !data) return <LoadingCircle />;
  return (
    <Box m="8px 0 0 0" width="100%" height="40vh" sx={gridStyles}>
      <DataGrid
        rows={data}
        columns={columns}
        onRowClick={handleRowClick}
        getRowId={getRowId}
        getRowClassName={getRowClassName}
      />
    </Box>
  );
};

export default ProjectsList;
