import React from "react";
import { Container, Grid, Typography, Paper } from "@mui/material";

const LogComponent = ({ updates }) => {
  return (
    <Container>
      <Typography variant="h4" mt={4} gutterBottom>
        Activity Log
      </Typography>
      <Grid container spacing={2}>
        {Array.isArray(updates) ? (
          updates
            .slice(0)
            .reverse()
            .map((sentence, index) => (
              <Grid item xs={12} key={index}>
                <Paper elevation={3} style={{ padding: "10px" }}>
                  <Typography>{sentence}</Typography>
                </Paper>
              </Grid>
            ))
        ) : (
          <Grid item xs={12} key="log">
            <Paper elevation={3} style={{ padding: "10px" }}>
              <Typography>{updates}</Typography>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default LogComponent;
