import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Box,
} from "@mui/material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import AddIcon from "@mui/icons-material/Add";
import { v4 as uuidv4 } from "uuid";
import { getMaterialTypes } from "../../data/getDataFromApi";
import useAuthToken from "../../hooks/useAuthToken";
import {
  addMaterialType,
  deleteMaterialType,
} from "../../data/updateDataToApi";
import { useNavigate } from "react-router-dom";
import NotificationSnackbar from "../../components/NotificationSnackbar";

const MaterialType = () => {
  const { token } = useAuthToken();
  const [materials, setMaterials] = useState([]);
  const [notification, setNotification] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (!token) return;
    const fetchData = async () => {
      try {
        await getMaterialTypes({
          token: token,
          setState: setMaterials,
        }).then((data) => {
          console.log(data);
        });
      } catch (error) {
        console.error("Error fetching material data:", error);
      }
    };

    fetchData();
  }, [token]);

  const handleMaterialChange = (name, nameIndex, value) => {
    const updatedMaterials = materials.map((material, index) => {
      if (index === nameIndex) {
        return {
          ...material,
          [name]: value,
          edited: true,
        };
      }
      return material;
    });
    setMaterials(updatedMaterials);
  };

  const handleRemoveMaterial = async (nameIndex) => {
   // const updatedMaterials = materials.filter(
     // (_, index) => index !== nameIndex
    //);

    // await deleteMaterialType({token: token,id: materials[nameIndex]._id,});
    setNotification("Delete is Disabled");
   // setMaterials(updatedMaterials);
  };

  const handleAddMaterial = () => {
    const newMaterial = {
      _id: uuidv4(),
      name: "",
      units: "",
      edited: false,
      isNew: true,
    };
    setMaterials([...materials, newMaterial]);
  };

  const handleSave = async () => {
    const editedItems = materials.filter((material) => material.edited);
    for (const item of editedItems) {
      const unitsArray =
      typeof item.units === "string"
        ? item.units.split(",").map((unit) => unit.trim())
        : typeof item.units === "object"
        ? item.units
        : [];
    
      const newItem = { ...item, units: unitsArray };

      const existsInState = materials.some(
        (existingItem) =>
          existingItem !== item && existingItem.name === newItem.name
      );

      if (existsInState || item.units === "") {

        setNotification(
          item.units === ""
            ? "Units cannot be empty"
            : "Duplicate Material Category"
        );
        return;
      }
      if (item.isNew) {
        const response = await addMaterialType({
          token: token,
          data: newItem,
        });
        if (response.success) {
          newItem._id = response.data.insertedId;
          newItem.edited = false;
          newItem.isNew = false;
          setNotification("Material Category Added");
        } else {
          console.error("Error adding item:", newItem);
        }
      } else {
        await addMaterialType({
          token: token,
          data: newItem,
          id: item._id,
        });
        setNotification("Material Category Updated");
        newItem.edited = false;
      }

      setMaterials((prevMaterials) => {
        const updatedMaterials = [...prevMaterials];
        const index = updatedMaterials.findIndex(
          (existingItem) => existingItem._id === item._id
        );
        updatedMaterials[index] = newItem;
        return updatedMaterials;
      });
    }
  };

  const handleCancel = () => {
    // Revert to the original state
    navigate(`/materials/edit/new`);
  };

  const handleNotificationClose = () => {
    setNotification(null);
  };

  return (
    <Box sx={{ flexGrow: 1, margin: 4 }}>
      <NotificationSnackbar
        open={!!notification}
        notification={notification}
        onClose={handleNotificationClose}
      />
      {materials.map((materialType, nameIndex) => (
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ marginBottom: "10px" }}
          key={materialType._id}
        >
          <Grid item>
            <TextField
              label={`Material Category ${nameIndex + 1}`}
              value={materialType.name}
              onChange={(e) =>
                handleMaterialChange("name", nameIndex, e.target.value)
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleRemoveMaterial(nameIndex)}>
                      <DeleteForeverOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ maxWidth: "300px", minWidth: "100px" }}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Units"
              value={materialType.units}
              onChange={(e) =>
                handleMaterialChange("units", nameIndex, e.target.value)
              }
              style={{ minWidth: "500px" }}
            />
          </Grid>
        </Grid>
      ))}

      {/* Button to add a new material */}
      <Button

        onClick={handleAddMaterial}
        color="secondary"
        style={{ marginRight: "50px" }}
      >
        <AddIcon /> Add Material Category
      </Button>

      <Button
        variant="contained"
        onClick={handleSave}
        color="secondary"
        style={{ marginRight: "10px" }}
      >
        Save
      </Button>
      <Button variant="outlined" onClick={handleCancel} color="secondary">
        Cancel
      </Button>
    </Box>
  );
};

export default MaterialType;
