import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import matchAndCombineData from "./matchAndCombindData";
import { formatRupees } from "../../utils/formatUtils";
import { useTheme } from "@mui/material";

const WeeklyStatsChart = ({ data, loading }) => {
  const [chartData, setChartData] = useState({
    expenses: [],
    revenue: [],
  });

  const theme = useTheme();

  useEffect(() => {
    if (!data || loading) {
      return;
    }

    const combinedData = matchAndCombineData(data.expenses, data.revenue);
    setChartData(combinedData);
  }, [data, loading]);

  if (loading || chartData?.expenses?.length === 0) {
    return <div>Loading...</div>;
  }

  const barChartTheme = {
    //background: "#222222",
    axis: {
      fontSize: "14px",
      tickColor: "#eee",
      ticks: {
        line: {
          stroke: "#555555"
        },
        text: {
          fill: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
        }
      },
      legend: {
        text: {
          fill: "#aaaaaa"
        }
      }
    },
    grid: {
      line: {
        stroke: "#555555"
      }
    }
  };

  return (
    <>
      <div style={{ height: "40vh", width:"100%" }}>
        <ResponsiveBar
          data={chartData}
          keys={["expenses", "revenue"]}
          indexBy="id"
          margin={{ top: 0, right: 10, bottom: 30, left: 120}}
          padding={0.3}
          axisBottom={null}
          colors={{ scheme: "paired" }}
          layout= "horizontal"
          groupMode="grouped"
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,    
          }}
          gridYValues={0}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: "color", modifiers: [["darker", 8]] }}
          label={function (d) {
            return <>{formatRupees(d.value)}</>;
          }}
          animate={true}
          theme={barChartTheme}
          motionStiffness={90}
          motionDamping={15}
          tooltip={({ id, value, color }) => (
            <strong style={{ from: color, modifiers: [["darker", 1]] }}>
             {id?.charAt(0)?.toUpperCase() + id?.slice(1)} : {formatRupees(value)}
            </strong>
          )}
         legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "row",
              justify: false,
              translateX: 30,
              translateY: 30,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemTextColor: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: theme.palette.mode === "dark" ? "#ffffff" : "#000000",                    
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </>
  );
};

export default WeeklyStatsChart;
