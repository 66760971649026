// formatUtils.js
const formatNumber = (input) => {
  if (input === undefined || input === null) {
    return 0;
  }

  const cleanInput = typeof input === 'string' ? input.replace(/,/g, '') : input; // Use replace only if input is a string
  const number = typeof cleanInput === 'string' ? parseFloat(cleanInput) : cleanInput;

  if (isNaN(number)) {
    // If it's not a valid number, return the original value
    return input;
  }
  return number.toLocaleString("en-IN");
};

const formatRupees = (input) => new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
  minimumFractionDigits: 0
}).format(input);

export {formatNumber, formatRupees};
