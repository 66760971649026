import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import FormHeader from "../../components/FormHeader";
import {
  Box,
  TextField,
  FormControl,
  FormHelperText,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import {
  getDashboardRecentRevenue,
  getProjects,
  getRevenue,
} from "../../data/getDataFromApi";
import { addRevenue, deleteRevenue } from "../../data/updateDataToApi";
import revenueValidationSchema from "../../data-validation/revenueValidationSchema";
import ConfirmDeleteDialog from "../../components/ConfirmDeleteDialog";
import useAuthToken from "../../hooks/useAuthToken";
import SubmitButton from "../../components/SubmitButton";
import CancelButton from "../../components/CancelButton";
import NotificationSnackbar from "../../components/NotificationSnackbar";
import useActivityLog from "../../hooks/useActivityLog";
import LogComponent from "../../components/LogComponent";
import { Autocomplete } from "@mui/material";
import ClientPayments from "../../components/dashboard/ClientPayments";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const ClientRevenue = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user, token } = useAuthToken();
  const [projects, setProjects] = useState([]);
  const [revenue, setRevenue] = useState([]);
  const [recentPayments, setRecentPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [notification, setNotification] = useState("");
  const activityLog = useActivityLog(
    id === "new" ? "added" : "made",
    user?.name,
    id === "new" ? "payment" : ""
  );

  useEffect(() => {
    if (!token) return;
    (async () => {
      try {
        await getProjects({ token, setState: setProjects });
        if (id !== "new") {
          const transaction = await getRevenue({ token, id });
          if (!transaction) {
            navigate("/revenue");
            throw new Error("Revenue not found");
          }
          // Prepopulate formik values with fetched transaction data
          const formattedDate = new dayjs(transaction.date);
          setRevenue(transaction);
          formik.setValues({
            ...transaction,
            date: formattedDate,
            //assign project dropdown with projectName and project Id
            project: transaction.project,
            paymentMethod: transaction.paymentMethod? transaction.paymentMethod : "Cash",
          });
        } else {
          await getDashboardRecentRevenue({
            token,
            setState: setRecentPayments,
            loading: setLoading,
          });
        }
      } catch (error) {
        if (error.message.includes("Resource not found")) {
          // Handle the case where the resource is not found (404)
          console.error("Transaction not found:", error);
          navigate("/revenue"); // You might want to redirect the user to a different page
        } else {
          // Handle other errors
          console.error("Error fetching transaction data:", error);
        }
      } finally {
        setLoading(false);
      }
    })();
    return () => {
      formik.resetForm();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, id, navigate]);

  const formik = useFormik({
    initialValues: {
      date: new dayjs(),
      project: null,
      amount: "",
      notes: "",
      paymentMethod: "Cash",
    },
    validationSchema: revenueValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        await addRevenue({
          data: {
            ...values,
            activityLog, // Include activityLog in the data object
            project: values.project._id,
          },
          id: id !== "new" ? id : "",
          token,
          user: user.name,
        }).catch((error) => {
          console.log(error);
        });
        if (id === "new") {
          setNotification("Payment Added successfully");
          await getDashboardRecentRevenue({
            token,
            setState: setRecentPayments,
            loading: setLoading,
          });
          formik.resetForm();
        } else {
          setNotification("Payment updated successfully");
          await getRevenue({
            token: token,
            id: id,
            setState: setRevenue,
          });
        }
      } catch (error) {
        setNotification("Fix the errors");
        console.error("Error creating transaction:", error.message);
      }
    },
  });

  const handleDeleteClick = () => setOpenConfirmDeleteDialog(true);

  const handleNotificationClose = () => setNotification("");

  const handleCancel = () => {
    if (navigate && navigate(-1)) {
      navigate(-1);
    } else {
      navigate("/revenue/all");
    }
  };

  const handleDeleteConfirm = () => {
    setOpenConfirmDeleteDialog(false);
    deleteRevenue({ id, token });

    setTimeout(() => {
      navigate("/revenue");
    }, 500);
  };

  const handleDeleteCancel = () => setOpenConfirmDeleteDialog(false);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="secondary" />
        <p>{loading === "loading" && "Loading..."}</p>
      </Box>
    );
  }

  return (
    <Box mx="auto" maxWidth={1000}>
      <form onSubmit={formik.handleSubmit}>
        <Box m="20px" position="relative">
          <NotificationSnackbar
            open={!!notification}
            notification={notification}
            onClose={handleNotificationClose}
          />
          <FormHeader
            title={id === "new" ? "Add New Payment" : "Edit Payment"}
            buttonName={id === "new" ? "" : `Delete`}
            handleButtonClick={handleDeleteClick}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="project"
                  options={projects}
                  value={formik.values.project}
                  getOptionLabel={(project) => project.name}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  onChange={(e, value) =>
                    formik.setFieldValue("project", value)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Projects" />
                  )}
                />
                <FormHelperText>
                  {formik.touched.project && formik.errors.project}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  type="Number"
                  label="Amount"
                  value={formik.values.amount}
                  onChange={(e) => formik.handleChange(e)}
                  name="amount"
                />
                <FormHelperText
                  error={formik.touched.amount && Boolean(formik.errors.amount)}
                >
                  {formik.touched.amount && formik.errors.amount}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                sx={{ minWidth: 220 }}
                error={formik.touched.date && Boolean(formik.errors.date)}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date"
                    format="DD/MM/YYYY"
                    value={formik.values.date}
                    onChange={(value) => formik.setFieldValue("date", value)}
                  />
                </LocalizationProvider>
                <FormHelperText>
                  {formik.touched.date && formik.errors.date}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="paymentMethod"
                  options={["Cash", "Cheque", "Bank Transfer", "Other"]}
                  value={formik.values?.paymentMethod}
                  onChange={(e, value) =>
                    formik.setFieldValue("paymentMethod", value)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Payment Method" />
                  )}
                />
                <FormHelperText>
                  {formik.touched.paymentMethod && formik.errors.paymentMethod}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  multiline
                  rows={2}
                  label="Notes"
                  value={formik.values.notes}
                  onChange={(e) => formik.handleChange(e)}
                  name="notes"
                />
                <FormHelperText
                  error={formik.touched.notes && Boolean(formik.errors.notes)}
                >
                  {formik.touched.notes && formik.errors.notes}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12} sm={12} m={5}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: "20px",
                }}
              >
                <SubmitButton
                  isSubmitting={formik.isSubmitting}
                  isNew={id === "new"}
                />
                <CancelButton onClick={handleCancel} />
              </Box>
            </Grid>
            {id !== "new" && <LogComponent updates={revenue.activityLog} />}
          </Grid>
          <ConfirmDeleteDialog
            open={openConfirmDeleteDialog}
            onCancel={handleDeleteCancel}
            onConfirm={handleDeleteConfirm}
            id={id}
            navigate={navigate}
          />
        </Box>
      </form>

      {id === "new" && (
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} sm={12} m={5}>
            <Typography
              variant="h2"
              component="div"
              style={{ textAlign: "left", width: "100%" }}
            >
              Recent Payments
            </Typography>
            <ClientPayments
              data={recentPayments}
              dashboard={false}
              loading={loading}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ClientRevenue;
