import React, { useState, useEffect } from "react";
import { Grid, Paper, Typography, Avatar } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

const ProfilePage = () => {
  const { isAuthenticated, user, isLoading } = useAuth0();
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    if (isAuthenticated && user) {
      setUserProfile(user);
    }
  }, [isAuthenticated, user]);

  if (isLoading || !userProfile) {
    return <div>Loading...</div>;
  }

  return (
    <Grid container spacing={3} justifyContent="center" alignItems="center">
      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ padding: 2, textAlign: "center" }}>
          <Avatar
            alt={userProfile.nickname}
            src={userProfile.picture}
            sx={{ width: 100, height: 100, margin: "0 auto 16px" }}
          />
          <Typography variant="h5" gutterBottom>
            {userProfile.name}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            {userProfile.nickname}
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            Email: {userProfile.email}
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            Email Verified: {userProfile.email_verified ? "Yes" : "No"}
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            User ID: {userProfile.sub}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Last Updated: {userProfile.updated_at}
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ProfilePage;
