// Desc: helper functions for transactions

const updateTotalCost = (formik, materialDetails) => {
  console.log("materialDetails", materialDetails);
    const totalCost = materialDetails.reduce(
      (sum, item) => sum + (item.itemTotal || 0),
      0
    );
    formik.setFieldValue("totalCost", parseFloat(totalCost.toFixed(2)));
  };

  
  export { updateTotalCost };