import React from "react";

const ProjectColumns = () => {
  return [
    { field: "_id", headerName: "Id", width: 100, hide: true },
    {
      field: "name",
      headerName: "Project",
      cellClassName: "name-column--cell",
      width: 200,
    },
    {
      field: "budget",
      headerName: "Budget",
      type: "number",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    { field: "location", headerName: "Location", width: 100 },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 100,
      renderCell: (params) => (
        <div>
          {params.value && new Date(params.value).toLocaleDateString('en-IN')}
        </div>
      ),},
      {
        field: "endDate",
        headerName: "End Date",
        width: 100,
        renderCell: (params) => (
          <div>
            {params.value && new Date(params.value).toLocaleDateString('en-IN')}
          </div>
        ),},
    { field: "clientName", headerName: "Client", width: 150 },
    { field: "status", headerName: "Status", width: 100 },
  ];
};

export default ProjectColumns;
