import moment from "moment";
const matchAndCombineData = (expenses, revenue) => {
  const combinedData = [];

  // Create a Map to efficiently store and retrieve data based on unique id (week + year)
  const dataMap = new Map();

  // Function to add or update data in the Map
  const updateDataMap = (week, year, type, amount) => {
    const id = `${moment().isoWeekYear(year).isoWeek(week).startOf('isoWeek').format("MMM D")} - ${moment().isoWeekYear(year).isoWeek(week).endOf('isoWeek').format("MMM D")}, ${year}`;

    if (!dataMap.has(id)) {
      dataMap.set(id, {
        id,
        time: `${week}-${year}`,
        expenses: 0,
        revenue: 0,
      });
    }

    dataMap.get(id)[type] += amount;
  };

  // Process transactions
  expenses?.forEach((expense) => {
    const { week, year } = expense._id;
    const totalAmount = expense.totalAmount;
    updateDataMap(week, year, "expenses", totalAmount);
  });

  // Process revenue
  revenue?.forEach((revenueEntry) => {
    const { week, year } = revenueEntry._id;
    const totalAmount = revenueEntry.totalAmount;
    updateDataMap(week, year, "revenue", totalAmount);
  });

  // Convert Map values to an array
  combinedData.push(...dataMap.values());
  // Sort the combined data based on the 'time' property

  combinedData.sort((a, b) => {
    const [aWeek, aYear] = a.time.split("-");
    const [bWeek, bYear] = b.time.split("-");

    if (aYear === bYear) {
      return aWeek - bWeek;
    }

    return aYear - bYear;
  });
  
  

  return combinedData;
};

export default matchAndCombineData;
