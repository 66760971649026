import React from "react";
import { Box, CircularProgress } from "@mui/material";

const LoadingCircle = () => {
  return (
    <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "10vh",
    }}
  >
    <CircularProgress color="secondary" />
    <p>Loading...</p>
  </Box>
  );
};

export default LoadingCircle;
