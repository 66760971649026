import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import PrintButton from "../../components/PrintButton";
import { updateTotalCost } from "./helpers";

import {
  Box,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  FormControl,
  FormHelperText,
  CircularProgress,
  Grid,
  Autocomplete,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import {
  getMaterialTypes,
  getMaterials,
  getProjects,
  getManagers,
  getVendors,
  getDashboardRecentTransactions,
  getVendorsForSelectedMaterialType,
} from "../../data/getDataFromApi";
import {
  addTransaction,
  deleteTransaction,
  addTransactionLog,
} from "../../data/updateDataToApi";
import { getTransactions } from "../../data/getDataFromApi";
import transactionValidationSchema from "../../data-validation/transactionValidationSchema";
import MaterialDropdown from "../../components/MaterialDropdown";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import FormHeader from "../../components/FormHeader";
import useAuthToken from "../../hooks/useAuthToken";
import ConfirmDeleteDialog from "../../components/ConfirmDeleteDialog";
import NotificationSnackbar from "../../components/NotificationSnackbar";
import SubmitButton from "../../components/SubmitButton";
import CancelButton from "../../components/CancelButton";
import useActivityLog from "../../hooks/useActivityLog";
import LogComponent from "../../components/LogComponent";
import PrintableInvoice from "../../components/printableInvoice/PrintableInvoice";
import RecentTransactions from "../../components/dashboard/RecentTransactions";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const EditTransaction = () => {
  const printableInvoiceRef = useRef();
  const { id } = useParams();
  const navigate = useNavigate();
  const [materialTypes, setMaterialTypes] = useState([]);
  const [managers, setManagers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [selectedMaterialType, setSelectedMaterialType] = useState(null); // [materialType, setMaterialType]
  const [loading, setLoading] = useState(true);
  const [transaction, setTransaction] = useState(null);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [notification, setNotification] = useState(null);
  const { user, token } = useAuthToken();
  const [recentTransactions, setRecentTransactions] = useState(null);
  const [formChanged, setFormChanged] = useState(false);
  const activityLog = useActivityLog(
    id === "new" ? "added" : "made",
    user?.name,
    id === "new" ? "transaction" : ""
  );
  const printLog = useActivityLog("printed", user?.name, "transaction");

  const [materialDetails, setMaterialDetails] = useState([]);

  const status = ["Draft", "Pending", "Approved", "Paid"];

  const formik = useFormik({
    initialValues: {
      materialType: "",
      materialDetails: [
        {
          name: null,
          quantity: "",
          unit: "",
          price: "",
          itemTotal: "",
        },
      ],
      date: new dayjs(),
      project: null,
      vendor: null,
      manager: null,
      totalCost: "",
      status: "Draft",
      notes: "",
    },
    validationSchema: transactionValidationSchema,
    onSubmit: async (values) => {
      try {
        await addTransaction({
          data: {
            ...values,
            activityLog, // Include activityLog in the data object
            project: values.project._id,
            manager: values.manager._id,
            vendor: values.vendor._id,
            materialType: values.materialType._id,
            userName: user?.name,
          },
          id: id !== "new" ? id : "",
          token: token,
        });
        if (id === "new") {
          setNotification("Invoice created successfully");
          formik.resetForm();
          setMaterialDetails([]);
          setFormChanged(false);
          await getDashboardRecentTransactions({
            token: token,
            setState: setRecentTransactions,
          });
        } else {
          setNotification("Invoice updated successfully");
          await getTransactions({
            token: token,
            id: id,
            setState: setTransaction,
          });
          setFormChanged(false);
        }
      } catch (error) {
        console.error("Error creating Invoice:", error.message);
      }
    },
  });

  useEffect(() => {
    if (!token) return;
    const fetchTransactionData = async () => {
      try {
        await Promise.all([
          getProjects({ token: token, setState: setProjects }),
          getMaterialTypes({ token: token, setState: setMaterialTypes }),
          getManagers({ token: token, setState: setManagers }),
          getVendors({ token: token, setState: setVendors }),
        ]);

        // Fetch transaction data only if id is not "new"
        if (id !== "new") {
          const transactionData = await getTransactions({
            token: token,
            id: id,
          });

          if (!transactionData) {
            // Handle case where transaction data is not found
            navigate("/transactions/all");
            throw new Error("Invoice not found");
          }

          const formattedDate = new dayjs(transactionData.date);

          formik.setValues({
            ...transactionData,
            date: formattedDate,
            project: transactionData.project,
          });
          setMaterialDetails(transactionData.materialDetails);
          setSelectedMaterialType(transactionData.materialType);
          setTransaction(transactionData);
        } else {
          // If id is "new," reset form and material details
          formik.resetForm();
          setMaterialDetails([]);
          await getDashboardRecentTransactions({
            token: token,
            setState: setRecentTransactions,
          });
        }
      } catch (error) {
        if (error.message.includes("Resource not found")) {
          // Handle the case where the resource is not found (404)
          console.error("Transaction not found:", error);
          navigate("/transactions/all"); // You might want to redirect the user to a different page
        } else {
          // Handle other errors
          console.error("Error fetching transaction data:", error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchTransactionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, token]);

  useEffect(() => {
    if (!token) return;
    if (!selectedMaterialType) return;
    const fetchMaterialData = async () => {
      try {
        await getMaterials({
          token: token,
          setState: setMaterials,
          id: selectedMaterialType ? selectedMaterialType._id : "",
        });
        await getVendorsForSelectedMaterialType({
          token: token,
          setState: setVendors,
          id: selectedMaterialType ? selectedMaterialType._id : "",
        });
        //add condition to reset vendor if material type is changed and if material details is empty
        if (materialDetails.length === 0) formik.setFieldValue("vendor", null);
      } catch (error) {
        console.error("Error fetching material data:", error);
      }
    };

    fetchMaterialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMaterialType, token]);

  useEffect(() => {
    updateTotalCost(formik, materialDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialDetails]);

  //add useeffect to resett formik.vendors when vendors change

  const handleAddMaterial = () => {
    setMaterialDetails((prevDetails) => [
      ...prevDetails,
      {
        name: null,
        quantity: 1,
        unit: "",
        price: "",
        itemTotal: "",
      },
    ]);
  };

  const handleRemoveMaterial = (index) => {
    setMaterialDetails((prevDetails) => {
      const updatedDetails = prevDetails.filter((_, i) => i !== index);

      // Update formik values
      formik.setFieldValue("materialDetails", updatedDetails);
      // Recalculate and update totalCost
      updateTotalCost(formik, materialDetails);
      return updatedDetails;
    });
  };
  const handleNotificationClose = () => {
    setNotification(null);
  };

  const handleCancel = () => {
    if (navigate && navigate(-1)) {
      navigate(-1);
    } else {
      navigate("/transactions/all");
    }
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDeleteDialog(true);
  };

  const handleCancelDelete = () => {
    setOpenConfirmDeleteDialog(false);
  };

  const handleDeleteConfirm = () => {
    setOpenConfirmDeleteDialog(false);

    deleteTransaction({ id: id, token: token })
      .then(() => {
        setNotification("Invoice deleted successfully");
        setTimeout(() => {
          navigate("/transactions/all");
        }, 500);
      })
      .catch((error) => {
        setNotification("Error deleting Invoice");
      });
  };

  //index "name" "value"
  const handleMaterialChange = (index, field, value) => {
    setFormChanged(true);
    if (value === null) return; // Prevents error when clearing the material name field
    setMaterialDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index][field] = value;

      if (field === "name") {
        const material = materials.find((item) => item.name === value);
        updatedDetails[index].unit = material?.unit[0];
      }

      const quantity = parseFloat(updatedDetails[index].quantity) || 0;
      const price = parseFloat(updatedDetails[index].price) || 0;

      updatedDetails[index].itemTotal = parseFloat(
        (quantity * price).toFixed(2)
      );
      formik.setFieldValue("materialDetails", updatedDetails);
      return updatedDetails;
    });
  };

  const handlePrint = useReactToPrint({
    content: () => printableInvoiceRef.current,
  });

  const handlePrintAndLog = async () => {
    addTransactionLog({
      id: id,
      token: token,
      data: {
        activityLog: printLog,
      },
    });
    handlePrint();
  };

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box m="20px" mx="auto" position="relative" maxWidth={1000}>
          <NotificationSnackbar
            open={!!notification}
            notification={notification}
            onClose={handleNotificationClose}
          />

          <FormHeader
            title={
              id === "new"
                ? "Add New Invoice"
                : `Edit Invoice ${transaction?.transactionNumber}`
            }
            buttonName={id === "new" ? "" : `Delete`}
            handleButtonClick={handleConfirmDelete}
          />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              {/* Project Dropdown */}
              <FormControl
                fullWidth
                sx={{ minWidth: 220 }}
                error={formik.touched.project && Boolean(formik.errors.project)}
              >
                <Autocomplete
                  disablePortal
                  id="project"
                  options={projects}
                  value={formik.values.project}
                  getOptionLabel={(project) => project.name}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  onChange={(e, value) =>
                    formik.setFieldValue("project", value)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Project" />
                  )}
                />
                <FormHelperText>
                  {formik.touched.project && formik.errors.project}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              {/* Manager Dropdown */}
              <FormControl
                fullWidth
                sx={{ minWidth: 220 }}
                error={formik.touched.manager && Boolean(formik.errors.manager)}
              >
                <Autocomplete
                  disablePortal
                  id="manager"
                  options={managers}
                  value={formik.values.manager}
                  getOptionLabel={(manager) => manager.name}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  onChange={(e, value) =>
                    formik.setFieldValue("manager", value)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Manager" />
                  )}
                />
                <FormHelperText>
                  {formik.touched.manager && formik.errors.manager}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              {/* Material Type */}
              <MaterialDropdown
                materialTypes={materialTypes}
                formik={formik}
                disabled={materialDetails?.length > 0}
                setSelectedMaterialType={setSelectedMaterialType}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              {/* Vendor Dropdown */}
              <FormControl
                fullWidth
                sx={{ minWidth: 220 }}
                error={formik.touched.vendor && Boolean(formik.errors.vendor)}
              >
                <Autocomplete
                  disablePortal
                  id="vendor"
                  options={vendors}
                  value={formik.values.vendor}
                  getOptionLabel={(vendor) => vendor.name}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  onChange={(e, value) => formik.setFieldValue("vendor", value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Vendor" />
                  )}
                />

                <FormHelperText>
                  {formik.touched.vendor && formik.errors.vendor}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              {/* Material Details */}
              <Grid
                flexDirection={{ xs: "column", sm: "row" }}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  flexDirection: "column",
                }}
              >
                {materials.length !== 0 &&
                  materialDetails?.map((material, index) => (
                    <Grid
                      container
                      flexDirection={{ xs: "column", sm: "row" }}
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        //gap: "10px",
                      }}
                      spacing={1}
                    >
                      <Grid item xs={12} sm={12} lg={3} xl={3}>
                        {/* Material Name */}
                        <FormControl
                          fullWidth
                          sx={{ minWidth: 240 }}
                          error={
                            formik.touched.materialDetails &&
                            formik.touched.materialDetails[index] &&
                            formik.touched.materialDetails[index].name &&
                            Boolean(
                              formik.errors.materialDetails &&
                                formik.errors.materialDetails[index] &&
                                formik.errors.materialDetails[index].name
                            )
                          }
                        >
                          <Autocomplete
                            id={`material-name-${index}`}
                            //update options to return the names of the materials in array
                            options={materials.map((item) => item.name)}
                            value={material.name}
                            onChange={(e, value) =>
                              handleMaterialChange(index, "name", value)
                            }
                            isOptionEqualToValue={(option, value) =>
                              option._id === value._id
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Material Name"
                                id={`material-name-${index}`}
                              />
                            )}
                          />

                          <FormHelperText>
                            {materialDetails[index] &&
                              materialDetails[index].name &&
                              formik.errors.materialDetails &&
                              formik.errors.materialDetails[index] &&
                              formik.errors.materialDetails[index].name}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={4} sm={4} lg={2} xl={2}>
                        {/* Quantity */}
                        <FormControl
                          fullWidth
                          sx={{ minWidth: 100 }}
                          error={
                            formik.touched.materialDetails &&
                            formik.touched.materialDetails[index] &&
                            formik.touched.materialDetails[index].quantity &&
                            Boolean(
                              formik.errors.materialDetails &&
                                formik.errors.materialDetails[index] &&
                                formik.errors.materialDetails[index].quantity
                            )
                          }
                        >
                          <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Quantity"
                            value={material.quantity}
                            onChange={(e) =>
                              handleMaterialChange(
                                index,
                                "quantity",
                                e.target.value
                              )
                            }
                            name="quantity"
                          />
                          <FormHelperText>
                            {formik.touched.materialDetails &&
                              formik.touched.materialDetails[index] &&
                              formik.errors.materialDetails &&
                              formik.errors.materialDetails[index] &&
                              formik.errors.materialDetails[index].quantity}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3} sm={3} lg={2} xl={2}>
                        {/* Unit */}
                        <FormControl
                          fullWidth
                          sx={{ minWidth: 150 }}
                          error={
                            formik.touched.materialDetails &&
                            formik.touched.materialDetails[index] &&
                            formik.touched.materialDetails[index].unit &&
                            Boolean(
                              formik.errors.materialDetails &&
                                formik.errors.materialDetails[index] &&
                                formik.errors.materialDetails[index].unit
                            )
                          }
                        >
                          <InputLabel id={`material-unit-label-${index}`}>
                            Unit
                          </InputLabel>
                          <Select
                            labelId={`material-unit-label-${index}`}
                            id={`unit-select-${index}`}
                            label="Unit"
                            value={material.unit}
                            onChange={(e) =>
                              handleMaterialChange(
                                index,
                                "unit",
                                e.target.value
                              )
                            }
                          >
                            {materials?.length === 0 && (
                              <MenuItem value="">No materials found</MenuItem>
                            )}

                            {materials
                              .filter((item) => item.name === material?.name)
                              .map((item) =>
                                item.unit.map((unit) => (
                                  <MenuItem key={unit} value={unit}>
                                    {unit}
                                  </MenuItem>
                                ))
                              )}
                          </Select>

                          <FormHelperText>
                            {formik.touched.materialDetails &&
                              formik.touched.materialDetails[index] &&
                              formik.errors.materialDetails &&
                              formik.errors.materialDetails[index] &&
                              formik.errors.materialDetails[index].unit}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={4} sm={4} lg={2} xl={2}>
                        {/* Price */}
                        <FormControl
                          fullWidth
                          sx={{ minWidth: 150 }}
                          error={
                            formik.touched.materialDetails &&
                            formik.touched.materialDetails[index] &&
                            formik.touched.materialDetails[index].price &&
                            Boolean(
                              formik.errors.materialDetails &&
                                formik.errors.materialDetails[index] &&
                                formik.errors.materialDetails[index].price
                            )
                          }
                        >
                          <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Price"
                            value={material.price}
                            onChange={(e) =>
                              handleMaterialChange(
                                index,
                                "price",
                                e.target.value
                              )
                            }
                            name="price"
                          />
                          <FormHelperText>
                            {formik.touched.materialDetails &&
                              formik.touched.materialDetails[index] &&
                              formik.errors.materialDetails &&
                              formik.errors.materialDetails[index] &&
                              formik.errors.materialDetails[index].price}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={4} sm={4} lg={2} xl={2}>
                        {/* Item Total */}
                        <FormControl
                          fullWidth
                          sx={{ minWidth: 150 }}
                          error={
                            formik.touched.itemTotal &&
                            Boolean(formik.errors.itemTotal)
                          }
                        >
                          <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="itemTotal"
                            value={material.itemTotal}
                            defaultValue={0}
                            onChange={formik.handleChange}
                            name="itemTotal"
                            disabled
                          />
                          <FormHelperText>
                            {formik.touched.itemTotal &&
                              formik.touched.itemTotal[index] &&
                              formik.errors.itemTotal &&
                              formik.errors.itemTotal[index] &&
                              formik.errors.itemTotal[index].price}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={2} sm={2} lg={1} xl={1} mt="15px">
                        {/* Remove Material Button */}
                        <Button
                          color="secondary"
                          variant="outline"
                          onClick={() => handleRemoveMaterial(index)}
                        >
                          <ClearIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  ))}

                {/* Add Material Button */}
                <Button
                  color="secondary"
                  variant="contained"
                  width="10px"
                  onClick={handleAddMaterial}
                  disabled={formik.values.materialType === ""}
                >
                  <AddIcon />
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              {/* Date Field */}
              <FormControl
                fullWidth
                sx={{ minWidth: 220 }}
                error={formik.touched.date && Boolean(formik.errors.date)}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date"
                    format="DD/MM/YYYY"
                    value={formik.values.date}
                    onChange={(value) => formik.setFieldValue("date", value)}
                  />
                </LocalizationProvider>
                <FormHelperText>
                  {formik.touched.date && formik.errors.date}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              {/* Total Cost */}
              <FormControl
                fullWidth
                sx={{ minWidth: 220 }}
                error={
                  formik.touched.totalCost && Boolean(formik.errors.totalCost)
                }
              >
                <TextField
                  fullWidth
                  id="total-cost"
                  name="totalCost"
                  variant="outlined"
                  type="text"
                  label="Total Cost"
                  value={formik.values.totalCost}
                  onChange={formik.handleChange}
                  disabled
                />
                <FormHelperText>
                  {formik.touched.totalCost && formik.errors.totalCost}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              {/* Status Dropdown */}
              <FormControl
                fullWidth
                sx={{ minWidth: 220 }}
                error={formik.touched.status && Boolean(formik.errors.status)}
              >
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                  labelId="status-label"
                  id="status"
                  value={formik.values.status}
                  label="Status"
                  onChange={(e) =>
                    formik.setFieldValue("status", e.target.value)
                  }
                >
                  {status?.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {formik.touched.status && formik.errors.status}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              {/* Notes */}
              <FormControl
                fullWidth
                sx={{ minWidth: 220 }}
                error={formik.touched.notes && Boolean(formik.errors.notes)}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={4}
                  type="text"
                  label="Notes"
                  value={formik.values.notes}
                  onChange={formik.handleChange}
                  name="notes"
                />
                <FormHelperText>
                  {formik.touched.notes && formik.errors.notes}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container justify="center" alignItems="center">
            <Grid item xs={12} sm={12} m={5}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: "20px",
                }}
              >
                {id !== "new" && (
                  <>
                    <div style={{ display: "none" }}>
                      <PrintableInvoice
                        ref={printableInvoiceRef}
                        data={transaction}
                      />
                    </div>
                    <PrintButton
                      variant="contained"
                      onClick={handlePrintAndLog}
                      disabled={formChanged}
                    />
                  </>
                )}

                <SubmitButton
                  isSubmitting={formik.isSubmitting}
                  isNew={id === "new"}
                />
                <CancelButton onClick={handleCancel} />
              </Box>

              {id !== "new" && (
                <LogComponent updates={transaction?.activityLog} />
              )}
            </Grid>
          </Grid>

          <ConfirmDeleteDialog
            open={openConfirmDeleteDialog}
            onCancel={handleCancelDelete}
            onConfirm={handleDeleteConfirm}
            id={id}
            navigate={navigate}
          />
        </Box>
      </form>
      {id === "new" && (
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} sm={12} m={5}>
            <Typography
              variant="h2"
              component="div"
              style={{ textAlign: "left", width: "100%" }}
            >
              Recent Invoices
            </Typography>
            <RecentTransactions
              data={recentTransactions}
              dashboard={false}
              loading={loading}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default EditTransaction;
