import React from "react";
import { Autocomplete, TextField, FormControl, FormHelperText } from "@mui/material";

const MaterialDropdown = ({ materialTypes, formik, disabled, setSelectedMaterialType }) => (
  <FormControl fullWidth sx={{ minWidth: 220 }} error={formik.touched.materialType && Boolean(formik.errors.materialType)}>
    <Autocomplete
      id="material-type"
      options={materialTypes}
      getOptionLabel={(item) => item.name}
      value={materialTypes.find((item) => item._id === formik?.values?.materialType?._id) || null}
      disabled={disabled}
      onChange={(e, value) => {
        formik.setFieldValue("materialType", value);
        setSelectedMaterialType(value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Material Type"
          name="materialType"
          disabled={disabled}
        />
      )}
    />

    <FormHelperText>{formik.touched.materialType && formik.errors.materialType}</FormHelperText>
  </FormControl>
);

export default MaterialDropdown;
