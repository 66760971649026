import * as XLSX from "xlsx";

const checkErrors = (
  row,
  isLoading,
  projects,
  vendors,
  materialTypes,
  managers
) => {
  if (isLoading) return;
  //console.log(row);
  const errors = {};
  if (row["CLIENT NAME"]) {
    const project = projects.find(
      (project) => project.name === row["CLIENT NAME"]
    );
    if (!project) {
      errors["CLIENT NAME"] = "Client Name not found";
    }
  } else {
    errors["CLIENT NAME"] = "Client Name is required";
  }
  if (row["VENDOR"]) {
    const vendor = vendors.find((vendor) => vendor.name === row["VENDOR"]);
    if (!vendor) {
      errors["VENDOR"] = "Vendor not found";
    }
  } else {
    errors["VENDOR"] = "Vendor is required";
  }
  if (!row["DATE"]) {
    errors["DATE"] = "Date is required";
  }
  if (row["CATEGORY"]) {
    const materialType = materialTypes.find(
      (materialType) => materialType.name === row["CATEGORY"]
    );
    if (!materialType) {
      errors["CATEGORY"] = "Category not found";
    }
  } else {
    errors["CATEGORY"] = "Category is required";
  }

  if (row["MIDDLEMAN"]) {
    const manager = managers.find(
      (manager) => manager.name === row["MIDDLEMAN"]
    );
    if (!manager) {
      errors["MIDDLEMAN"] = "Manager is required";
    }
  } else {
    errors["MIDDLEMAN"] = "Manager is required";
  }

  //return errors as string
  return errors;
};

const checkAllErrors = (
  rows,
  setRows,
  setErrors,
  isLoading,
  projects,
  vendors,
  materialTypes,
  managers
) => {
  const updatedRows = [...rows];
  let errorCount = 0;

  rows.forEach((row) => {
    const errors = checkErrors(
      row,
      isLoading,
      projects,
      vendors,
      materialTypes,
      managers
    );
    if (Object.keys(errors).length > 0) {
      errorCount++;
    }
    const rowIndex = updatedRows.findIndex((r) => r.index === row.index);
    updatedRows[rowIndex] = {
      ...updatedRows[rowIndex],
      errors: Object.keys(errors).length > 0 ? Object.values(errors) : null,
    };
  });
  setRows(updatedRows);
  setErrors(errorCount > 0);
};

const validateFileType = (file) => {
  if (file.name.endsWith(".xlsx") || file.name.endsWith(".csv")) {
    return true;
  }
  return false;
};

const validateFileSize = (file) => {
  if (file.size > 30000000) {
    return false;
  }
  return true;
};

const readDataFromXLSX = (arrayBuffer) => {
  const workbook = XLSX.read(arrayBuffer, {
    type: "array",
    emptyRows: true,
  });
  const firstSheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[firstSheetName];

  const range = {
    s: { r: 0, c: 0 }, // start from the first cell (A1)
    e: { r: 500, c: 11 }, // end at the 10th row
  };

  //clean up empty rows

  let data = XLSX.utils.sheet_to_json(worksheet, {
    header: 0,
    range: range,
  });

  return data.filter((item) => {
    return item["CLIENT NAME"] != null && item["CATEGORY"] != null;
  });
};


const readDataFromCSV = (arrayBuffer) => {
  const text = new TextDecoder("utf-8").decode(arrayBuffer);
  const workbook = XLSX.read(text, { type: "string" });
  const firstSheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[firstSheetName];
  let data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  return data;
}

const checkMissingFields = (data) => {
  const requiredFields =  [
    "CLIENT NAME",
    "CATEGORY",
    "VENDOR",
    "MIDDLEMAN",
    "DATE",
    "MATERIAL",
    "QUANTITY",
    "UNIT",
    "AMOUNT",
  ];
  const missingFields = [];
  const header = Object.keys(data[0]);
  requiredFields.forEach((field) => {
    if (!header.includes(field)) {
      missingFields.push(field);
    }
  });
  return missingFields;
}


const cleanupData = (data) => {
  let cleanedData = [];
  let materials = [];
  let previousClientName, previousVendor, previousDate, previousCategory, previousMiddleman;
  let rowStart = 0, rowEnd = 0, i = 0, previousTotal = 0;
  console.log("tis is the data:");
  console.log(data);
  data.forEach((item, index) => {
    // Cleanup date
    if (item["DATE"])
     data[index]["DATE"] = new Date(item["DATE"]);

    //cleanup date using excel date format:
    //if item["date"] is not in date format then convert it to date format
    if (item["DATE"]) {
      let date = new Date(1900, 0, item["DATE"] - 1);
      data[index]["DATE"] = date.toLocaleDateString();
    }

    // Cleanup amount, quantity, rate, total
    ["AMOUNT", "QUANTITY", "RATE", "TOTAL"].forEach((key) => {
      if (item[key]) data[index][key] = parseFloat(item[key]).toFixed(2);
    });

    // Cleanup unit and category
    ["UNIT", "CATEGORY"].forEach((key) => {
      if (item[key]) data[index][key] = item[key].toUpperCase();
    });

    // Cleanup client name, vendor, and middleman
    ["CLIENT NAME", "VENDOR", "MIDDLEMAN"].forEach((key) => {
      if (item[key]) data[index][key] = item[key].trim();
    });

    // Check if the current row matches the previous row
    if (item["CLIENT NAME"] === previousClientName &&
      item["VENDOR"] === previousVendor &&
      item["DATE"] === previousDate &&
      item["CATEGORY"] === previousCategory) {
      data[index]["index"] = i;
      data[index]["rowID"] = index + 1;
      rowEnd = index;
      materials.push([
        item["MATERIAL"],
        item["QUANTITY"],
        item["UNIT"],
        item["RATE"],
        (item["QUANTITY"] * item["RATE"]).toFixed(2),
      ]);
      previousTotal += parseFloat(
        (item["QUANTITY"] * item["RATE"]).toFixed(2)
      );
    } else {
      data[index]["index"] = ++i;
      data[index]["rowID"] = index + 1;

      if (index !== 0) {
        cleanedData.push({
          "CLIENT NAME": previousClientName,
          VENDOR: previousVendor,
          DATE: previousDate,
          CATEGORY: previousCategory,
          MIDDLEMAN: previousMiddleman,
          MATERIALS: materials,
          rowRange: `${rowStart + 2}--${rowEnd + 2}`,
          index: i,
          id: `row${i}`,
          total: previousTotal.toFixed(2),
        });
      }

      materials = [];
      rowStart = index;
      rowEnd = index;
      materials.push([
        item["MATERIAL"],
        item["QUANTITY"],
        item["UNIT"],
        item["RATE"],
        (item["QUANTITY"] * item["RATE"]).toFixed(2),
      ]);
      previousTotal = parseFloat(
        (item["QUANTITY"] * item["RATE"]).toFixed(2)
      );
      previousClientName = item["CLIENT NAME"];
      previousVendor = item["VENDOR"];
      previousDate = item["DATE"];
      previousCategory = item["CATEGORY"];
      previousMiddleman = item["MIDDLEMAN"];
    }
  });

  // Include the last set of data after the loop
  cleanedData.push({
    "CLIENT NAME": previousClientName,
    VENDOR: previousVendor,
    DATE: previousDate,
    CATEGORY: previousCategory,
    MIDDLEMAN: previousMiddleman,
    MATERIALS: materials,
    rowRange: `${rowStart + 2}--${rowEnd + 2}`,
    index: i + 1,
    id: `row${i + 1}`,
    total: previousTotal,
  });

  return cleanedData;
}



export {
  checkErrors,
  checkAllErrors,
  validateFileType,
  validateFileSize,
  readDataFromXLSX,
  readDataFromCSV,
  checkMissingFields,
  cleanupData
};
