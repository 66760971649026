const useActivityLog = (action, user, entityType = "") => {

  const timestamp = new Date().toLocaleString("en-IN", {
    timeZone: "Asia/Kolkata",
  });
  let entityLabel = entityType ? `a ${entityType}` : "changes";

  return `${user} ${action} ${entityLabel} on ${timestamp}`;
};

export default useActivityLog;
