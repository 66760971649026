import { DataGrid } from "@mui/x-data-grid";
import { useTheme, Box } from "@mui/material";
import getGridStyles from "../gridStyles";
import LoadingCircle from "../LoadingCircle";
import { useNavigate } from "react-router-dom";
import { formatNumber } from "../utils/formatUtils";
const ClientPayments = ({ loading, data, dashboard }) => {
  const theme = useTheme();
  const gridStyles = getGridStyles(theme);
  const navigate = useNavigate();

  if (loading || !data) return <LoadingCircle />;

  const columns = [
    ...(dashboard
      ? []
      : [{ field: "project", headerName: "Project", flex: 1,
      renderCell: (params) => <>{params.value.name}</>
     }]),
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      renderCell: (params) => <>{formatNumber(params.value)}</>,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      renderCell: (params) => (
        <div>
          {params.value && new Date(params.value).toLocaleDateString("en-IN")}
        </div>
      ),
    },
    { field: "notes", headerName: "Notes", flex: 1 },
  ];

  const handleRowClick = (params) => {
    navigate(`/revenue/edit/${params.row._id}`);
  };

  
  return (
    <Box m="8px 0 0 0" width="100%" height="40vh" sx={gridStyles}>
      <DataGrid
        rows={data}
        columns={columns}
        onRowClick={handleRowClick} {...data}
        getRowId={(row) => row._id}
      />
    </Box>
  );
};

export default ClientPayments;
