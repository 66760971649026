import React from "react";
import { ResponsiveTreeMap } from "@nivo/treemap";
import { useEffect, useState } from "react";
import LoadingCircle from "./LoadingCircle";
import { useTheme } from "@mui/material";
import { darken } from "@mui/material/styles";

const TreeMapChart = ({ data, loading }) => {
  const [treeMapData, setTreeMapData] = useState();
  const theme = useTheme();

  useEffect(() => {
    if (!data) return;
    // Manipulate data here if needed
    // For example, calculate the total value for each category
    const formattedData = data.map((item) => ({
      name: item.label,
      value: item.value,
    }));

    const cleanData = {
      children: formattedData,
    };

    // Update state with the formatted data
    setTreeMapData(cleanData);
  }, [data]); // Empty dependency array to ensure useEffect runs only once

  if (loading || !data) return <LoadingCircle />;

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ResponsiveTreeMap
        data={treeMapData}
        identity="name"
        value="value"
        innerPadding={3}
        outerPadding={3}
        leavesOnly={true}
        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
        labelSkipSize={12}
        label={(e) => e.id + " (" + e.formattedValue + ")"}
        //if dark theme use purple_blue_green else use red_yellow_blue
        colors={{ scheme: "green_blue" }}
        nodeOpacity={0.6}
        tooltip={({ node }) => (
          <strong
            style={{
              background: darken(node.color, 0.1),
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {node.pathComponents.join(" ")}: {node.formattedValue}
          </strong>
        )}
        labelTextColor={theme.palette.mode === "dark" ? "#ffffff" : "#000000"}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.1]],
        }}
      />
    </div>
  );
};

export default TreeMapChart;
