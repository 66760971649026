// CancelButton.js
import React from "react";
import Button from "@mui/material/Button";

const CancelButton = ({ onClick }) => (
  <Button
    type="button"
    variant="outlined"
    onClick={onClick}
    color="secondary"
    style={{ marginRight: "8px" }}
  >
    Cancel
  </Button>
);

export default CancelButton;
