const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:6060/api";

const fetchData = async (endpoint, options = {}) => {
  const { token, setState } = options;

  try {
    const headers = token ? { Authorization: `Bearer ${token}` } : {};

    const response = await fetch(`${apiUrl}/${endpoint}`, { headers });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    if (setState) {
      setState(data);
    }

    return data;
  } catch (error) {
    console.error(`Error fetching ${endpoint} data from the API:`, error);
    throw error;
  }
};

const createGetDataFunction = (endpoint) => async (options = {}) => {
  try {
    const { id, materialType, ...otherOptions } = options;
    let updatedEndpoint = endpoint;
    if (id) {
      updatedEndpoint += `/${id}`;
      if (materialType) {
        updatedEndpoint += `/${materialType}`;
      }
    } 
    const result = await fetchData(updatedEndpoint, otherOptions);
    return result;
  } catch (error) {
    if (error.message.includes('404')) {
      console.error(`Resource not found: ${endpoint}`);
      throw new Error(`Resource not found`);
    }
    console.error(`Error fetching ${endpoint}:`, error);
    throw error;
  }
};

export const getTransactions = createGetDataFunction('transactions');
export const getRevenue = createGetDataFunction('revenue');
export const getVendors = createGetDataFunction('vendors');
export const getVendorsClean = createGetDataFunction('vendors/clean');
export const getOnlyClientNames = createGetDataFunction('clients/names');
export const getOnlyVendorNames = createGetDataFunction('vendors/names');
export const getOnlyManagerNames = createGetDataFunction('managers/names');
export const getManagers = createGetDataFunction('managers');
export const getProjects = createGetDataFunction('projects');
export const getProjectsClean = createGetDataFunction('projects/clean');
export const getDashboardMaterialsTotal = createGetDataFunction('dashboard/get-materials-total');
export const getDashboardProjectTotal = createGetDataFunction('dashboard/get-project-total');
export const getDashboardRecentTransactions = createGetDataFunction('dashboard/get-recent-transactions');
export const getDashboardMaterialTypeTotal = createGetDataFunction('dashboard/get-material-type-total');
export const getDashboardClientPayments = createGetDataFunction('dashboard/get-client-payments');
export const getDashboardVendorPayments = createGetDataFunction('dashboard/get-vendor-payments');
export const getDashboardPendingVendorPaymentsSum = createGetDataFunction('dashboard/get-pending-vendor-payments-sum');
export const getDashboardExpensesTotal = createGetDataFunction('dashboard/get-expenses-total');
export const getDashboardWeeklyTransactions = createGetDataFunction('dashboard/get-weekly-trasaction-summary');
export const getDashboardRecentRevenue = createGetDataFunction('dashboard/get-recent-revenue');
export const getTransactionsByProject = createGetDataFunction('transactions/get-transactions-by-project');
export const getRevenueByProject = createGetDataFunction('revenue/get-revenue-by-project');
export const getUsersPermissions = createGetDataFunction('users/get-permissions');
export const getMaterials = createGetDataFunction('materials/get-materials');
export const getMaterialTypes = createGetDataFunction('materialtypes');
export const getVendorsForSelectedMaterialType = createGetDataFunction('vendors/get-vendors-for-selected-material-type');

