import * as yup from 'yup';

const projectValidationSchema = yup.object().shape({
  name: yup.string().required('Project name is required'),
  location: yup.string().required('Location is required'),
  startDate: yup.date().required('Start date is required'),
  endDate: yup.date().required('End date is required'),
  clientName: yup.string().required('Client Name is required'),
  budget: yup
    .number()
    .typeError('Budget must be a number')
    .positive('Budget must be a positive number')
    .required('Budget is required'),
  status: yup.string().required('Status is required'),
});

export default projectValidationSchema;
