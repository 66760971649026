import { React, useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getProjects} from "../../data/getDataFromApi";
import ProjectColumns from "./projectColumns";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { useAuth0 } from "@auth0/auth0-react";
import gridStyles from "../../components/gridStyles";

const Projects = () => {
  const [projectData, setprojectData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { getAccessTokenSilently, logout } = useAuth0();
  const [token, setToken] = useState(null);
  const navigate = useNavigate();
  const columns = ProjectColumns();
  const getRowId = (row) => row._id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken);
      } catch (error) {
        console.error("Error fetching access token:", error);
        if (error.message.indexOf("Refresh Token") !== -1) {
          // Log out the user if the refresh token is missing
          logout();
        }
        
      }
    };

    fetchData();
  }, [getAccessTokenSilently, logout]);

  useEffect(() => {
    if (token) {
      getProjects({ token: token, setState: setprojectData })
        .then(() => setLoading("loaded"))
        .catch((error) => {
          setLoading("ERROR, Please contact Admin");
        });
    }
  }, [token]);

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Project Information"
          subtitle="Welcome to Project Data"
        />
        <Box>
          <Button
            component={Link}
            to="/projects/edit/new"
            variant="contained"
            color="secondary"
          >
            Add New Project
          </Button>
        </Box>
      </Box>
      <Box
        m="8px 0 0 0"
        width="100%"
        height="80vh"
        sx={gridStyles}
      >
        <DataGrid
          rows={projectData}
          columns={columns}
          slots={{
              toolbar: GridToolbar,
            }}
          loading={loading !== "loaded"}
          getRowId={getRowId}
          initialState={{
              columns: {
                columnVisibilityModel: {
                  _id: false,
                },
              },
            }}
          onCellDoubleClick={(params, event) => {
                navigate(`/projects/edit/${params.row._id}`);
            }}
        />
      </Box>
    </Box>
  );
};

export default Projects;
