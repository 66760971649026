import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  // Function to navigate to the home page
  const redirectToHome = () => {
    navigate("/");
  };

  return (
    <div>
      <h1>404 - Route not found!</h1>
      <p>The requested page does not exist.</p>
      <button onClick={redirectToHome}>Go to Home</button>
    </div>
  );
};

export default NotFound;
