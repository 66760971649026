import { addTransaction } from "../../../data/updateDataToApi";

export const submitBulkData = async (
  data,
  projects,
  materialtypes,
  vendors,
  managers,
  token,
  activityLog,
  user
) => {
  let formData = {};

  data.forEach(async (item, index) => {
    let project = (({ name, _id }) => ({ name, _id }))(
      projects.find((project) => project.name === item["CLIENT NAME"]) || {}
    );
    let materialtype = (({ name, _id }) => ({ name, _id }))(
      materialtypes.find(
        (materialtype) => materialtype.name === item["CATEGORY"]
      ) || {}
    );
    let vendor = (({ name, _id }) => ({ name, _id }))(
      vendors.find((vendor) => vendor.name === item["VENDOR"]) || {}
    );
    let manager = (({ name, _id }) => ({ name, _id }))(
      managers.find((manager) => manager.name === item["MIDDLEMAN"]) || {}
    );

    console.log("token" + token);

    formData = {
      materialType: materialtype._id,
      materialDetails: item["MATERIALS"].map((material) => {
        return {
          name: material[0],
          quantity: parseFloat(material[1]),
          unit: material[2],
          price: parseFloat(material[3]),
          itemTotal: parseFloat(material[4]),
        };
      }),
      totalCost: item["total"],
      date: item["DATE"],
      vendor: vendor._id,
      manager: manager._id,
      project: project._id,
      status: "Draft",
      notes: "",
      activityLog: activityLog,
      userName: user?.name,
    };
    await addTransaction({ data: formData, id: "", token: token });

    console.log(formData);
  });

  return;
};
// Path: src/pages/transactions/import/submitBulkData.js
