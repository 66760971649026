import React from "react";
import { Button, styled } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useAuth0 } from "@auth0/auth0-react";

const StyledLogoutButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  "&:hover": {
   // backgroundColor: theme.palette.error.light,
  },
}));

export const LogoutButton = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <StyledLogoutButton
      onClick={handleLogout}
      startIcon={<ExitToAppIcon />}
      fullWidth
    >
      Log Out
    </StyledLogoutButton>
  );
};
