import React from "react";
import { ResponsivePie } from "@nivo/pie";
import { useEffect, useState } from "react";
import { formatNumber } from "./utils/formatUtils";
import LoadingCircle from "./LoadingCircle";
import { darken } from "@mui/material/styles";

const PieChart = ({ data, loading }) => {
  const [chartData, setChartData] = useState([]);


  useEffect(() => {
    if (!data) return;
    const chartData = data.map((item, index) => ({
      id: item._id,
      label: item.label,
      value: item.value,
    }));
    setChartData(chartData);
  }, [data]);

  if (loading || !data) return <LoadingCircle />;

  return (
    <ResponsivePie
      theme={{

        labels: {
          text: {
            fontSize: 14,
            fontWeight: 600,
          },
        },
      }}
      data={chartData}
      valueFormat={(value) => formatNumber(value)}
      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
     
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      arcLinkLabelsSkipAngle={10}
      colors={{ scheme: 'green_blue' }}
      arcLinkLabelsThickness={2}
      arcLinkLabelsTextColor={{ from: "color", modifiers: [["darker", 1.2]] }}
      arcLinkLabelsColor={{ from: "color", modifiers: [["darker", 1.2]]  }}
      arcLabelsTextColor={{ from: "color", modifiers: [["darker", 5]] }}
      arcLabelsSkipAngle={10}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
       // add background for tooltip 
       tooltip={(props) => {
        return (
          <div
            style={{
              background: props.datum.color,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <span style={{ color: darken(props.datum.color, 1)  }}>{props.datum.label}</span>
            <br />
            <span style={{ color: darken(props.datum.color, 1)}}>
              {formatNumber(props.datum.value)}
            </span>
          </div>
        );
      }}
      // legends={[
      //     {
      //         anchor: 'right',
      //         direction: 'column',
      //         justify: false,
      //         translateX: 0,
      //         translateY: 56,
      //         itemsSpacing: 0,
      //         itemWidth: 100,
      //         itemHeight: 21,
      //         itemTextColor: colors.grey[100],
      //         itemDirection: 'left-to-right',
      //         itemOpacity: 1,
      //         symbolSize: 18,
      //         symbolShape: 'circle',
      //         effects: [
      //             {
      //                 on: 'hover',
      //                 style: {
      //                     itemTextColor: colors.greenAccent[400],
      //                 }
      //             }
      //         ]
      //     }
      // ]}
    />
  );
};

export default PieChart;
