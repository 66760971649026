// gridStyles.js

import { tokens } from "../theme";
const getGridStyles = (theme) => {
  const colors = tokens(theme.palette.mode);
  return {
    "& .MuiDataGrid-root": {
      border: "none",
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor : theme.palette.tableHeader.default,
    },
    "& .MuiDataGrid-Footer": {
      backgroundColor : theme.palette.tableHeader.default,
    },
    "& .MuiDataGrid-virtualScroller": {
      backgroundColor: colors.primary[400],
    },
    "& .MuiDataGrid-footerContainer": {
      borderTop: "none",
      backgroundColor : theme.palette.tableHeader.default,
    },
    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
      color: theme.palette.tableHeader.toolbar,
    },
  };
};

export default getGridStyles;
