// SubmitButton.js
import React from "react";
import Button from "@mui/material/Button";

const SubmitButton = ({ isSubmitting, isNew }) => (
  <Button
    type="submit"
    variant="contained"
    color="secondary"
    disabled={isSubmitting}
    style={{ marginRight: "8px" }}
  >
    {isSubmitting ? "Submitting..." : isNew ? "Add New " : "Save Changes"}
  </Button>
);

export default SubmitButton;
