// MaterialsSummary.js

import React, { useState, useMemo } from "react";
import { useTheme } from "@mui/material";
import LoadingCircle from "../LoadingCircle";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import getGridStyles from "../gridStyles";
import { formatNumber } from "../utils/formatUtils";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";

const MaterialsSummary = ({ data, loading, budget, onMaterialTypeSelect }) => {
  const theme = useTheme();
  const gridStyles = getGridStyles(theme);

  // Track the currently selected material type
  const [selectedMaterialType, setSelectedMaterialType] = useState(null);
  const CustomFooter = () => {
    return (
      <GridToolbarContainer
        style={{ backgroundColor: theme.palette.tableHeader.default }}
        sx={{ p: 1 }}
      >
        <div>
          {selectedMaterialType && (
            <Button
              color="secondary"
              sx={{ mr: 1, p: 0 }}
              onClick={() => {
                onMaterialTypeSelect(null);
                setSelectedMaterialType(null);
              }}
            >
              Reset
            </Button>
          )}
          <GridToolbarExport
            printOptions={{
              hideFooter: true,
              hideToolbar: true,
            }}
          />
        </div>
      </GridToolbarContainer>
    );
  };

  const calculatePercentages = useMemo(() => {
    if (!budget || !data) return [];

    return data?.map((material) => ({
      ...material,
      percent: ((material.value / budget) * 100).toFixed(2) + "%",
    }));
  }, [data, budget]);

  const handleRowClick = (params) => {
    // If a material type is already selected, do nothing
    if (selectedMaterialType) {
      return;
    }
    // Get the material type of the clicked row
    const clickedMaterialType = params.row.materialType;
    // Set the selected material type
    setSelectedMaterialType(clickedMaterialType);
    // Notify the parent component about the selection
    onMaterialTypeSelect(clickedMaterialType);
  };

  if (loading || !data) return <LoadingCircle />;

  const columns = [
    { field: "label", headerName: "Material Type", flex: 1 },
    {
      field: "value",
      headerName: "Total Expenses",
      flex: 1,
      renderCell: (params) => <>{formatNumber(params.value)}</>,
    },

    {
      field: "percent",
      headerName: "Percentage of Total",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "row",
              height: "100%",
              alignItems: "center",
              width: "100%",
            }}
          >
                      
            <LinearProgress
              value={parseFloat(params.value.replace("%", "")) || 0}
              variant="determinate"
              color="secondary"
              sx={{ width: "80%", height: "60%" }}
            />  <Typography variant="body2" sx={{width:"20%", position: "absolute", left: "30%"}}>{params.value}</Typography>

          </Box>
        );
      },
    },
  ];

  return (
    <Box m="8px 0 0 0" width="100%" height="400px" sx={gridStyles}>
      <DataGrid
        rows={calculatePercentages}
        columns={columns}
        getRowId={(row) => row._id}
        onRowClick={handleRowClick}
        slots={{
          footer: CustomFooter,
        }}
      />
    </Box>
  );
};

export default MaterialsSummary;
