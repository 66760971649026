import * as yup from "yup";

const revenueValidationSchema = yup.object().shape({
  project: yup
    .object()
    .typeError("Please select a project")
    .required("project is required"),

  date: yup
    .date()
    .typeError("Date must be a date")
    .required("Date is required"),

  amount: yup
    .number()
    .typeError("Amount must be a number")
    .required("Amount is required"),
  
    paymentMethod: yup
    .string()
    .typeError("Please select a payment method")
    .required("Payment method is required"),

});

export default revenueValidationSchema;
