import { DataGrid } from "@mui/x-data-grid";
import { useTheme, Box, Button } from "@mui/material";
import getGridStyles from "../gridStyles";
import { useNavigate } from "react-router-dom";
import LoadingCircle from "../LoadingCircle";
import { formatNumber } from "../utils/formatUtils";
import { GridToolbarContainer } from "@mui/x-data-grid";
const RecentTransactions = ({ data, dashboard, loading }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const gridStyles = getGridStyles(theme);

  const CustomFooter = () => {
    return (
      <GridToolbarContainer
        style={{ backgroundColor: theme.palette.tableHeader.default }}
        sx={{ p: 1 }}
      >
        <div>
          <Button
            color="secondary"
            sx={{ mr: 1, p: 0 }}
            onClick={() => {
              navigate(`/transactions/${data[0].project._id}`);
            }}
          >
            View All
          </Button>
        </div>
      </GridToolbarContainer>
    );
  };

  const columns = [
    ...(dashboard
      ? []
      : [
          {
            field: "project",
            headerName: "Project",
            flex: 1,
            renderCell: (params) => <>{params.value.name}</>,
          },
        ]),
    { field: "transactionNumber", headerName: "Invoice No", flex: 1 },
    {
      field: "materialType",
      headerName: "Material Type",
      flex: 1,
      renderCell: (params) => <>{params.value.name}</>,
    },
    { field: "vendor", headerName: "Vendor", flex: 1 },
    {
      field: "totalCost",
      headerName: "Total Cost",
      flex: 1,
      renderCell: (params) => <>{formatNumber(params.value)}</>,
    },
    {
      field: "date",
      headerName: "Date",
      width: 100,
      flex: 1,
      renderCell: (params) => (
        <div>
          {params.value && new Date(params.value).toLocaleDateString("en-IN")}
        </div>
      ),
    },
    { field: "status", headerName: "Status", flex: 1 },
  ];

  const handleRowClick = (params) => {
    navigate(`/transactions/edit/${params.row._id}`);
  };

  if (loading || !data) return <LoadingCircle />;
  return (
    <Box m="8px 0 0 0" width="100%" height="40vh" sx={gridStyles}>
      <DataGrid
        rows={data}
        columns={columns}
        onRowClick={handleRowClick}
        {...data}
        getRowId={(row) => row._id}
        slots={{
          footer: CustomFooter,
        }}
      />
    </Box>
  );
};

export default RecentTransactions;
